import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildType, ENVIRONMENT, Environment, ErrorCode } from '@domains';
import { LocalStorageService } from '@rspl-api';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  lock = 'refresh_token_in_progress';
  tokenRefreshedSource: Subject<string> = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  refreshTimeout: any;
  refreshInProgress = false;
  location?: { latitude: number; longitude: number };
  locationTime?: Moment;
  tabsChannel = new BroadcastChannel('tabs_channel');
  tabId: string = this.getUniqueId();
  masterTab?: string = this.tabId;

  refreshTokenInProgress = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    @Inject(ENVIRONMENT) private config: Environment,
    private zone: NgZone
  ) {
    this.tabsChannel.onmessage = (event) => {
      this.zone.run(() => {
        if (event.data.type === 'REGISTER_TAB') {
          // Update master tab
          this.masterTab = event.data.id;
          if (this.refreshInProgress) {
            this.refreshInProgress = false;
            this.tabsChannel.postMessage({
              type: 'REFRESH_TOKEN',
            });
            this.refreshToken();
          }
        } else if (event.data.type === 'UNREGISTER_TAB') {
          // Update master tab
          if (this.masterTab === event.data.id) {
            this.tabsChannel.postMessage({
              type: 'REGISTER_TAB',
              id: this.tabId,
            });
          }
        } else if (event.data.type === 'REFRESH_TOKEN') {
          this.refreshInProgress = true;
          this.refreshToken();
        } else if (event.data.type === 'REFRESH_TOKEN_FINISHED') {
          if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
          this.refreshInProgress = false;
          this.tokenRefreshedSource.next(event.data.token);
        } else if (event.data.type === 'REFRESH_TOKEN_FAILED') {
          if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
          this.authService.logout(false);
          this.tokenRefreshedSource.error(new Error('refresh token failed'));
        }
      });
    };
    //register tab
    this.tabsChannel.postMessage({
      type: 'REGISTER_TAB',
      id: this.tabId,
    });
    //unregister tab before closing/refreshing tab
    window.addEventListener('beforeunload', () => {
      this.tabsChannel.postMessage({ type: 'UNREGISTER_TAB', id: this.tabId });
      return true; //Webkit, Safari, Chrome
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('version.json') ||
      request.url.includes('/users/refresh') ||
      !request.url.includes(this.config.urls.baseUrl)
    ) {
      return next.handle(request);
    }
    request = this.addAuthHeader(request);
    return (
      this.config.trackLocation ? this.setLocation(request) : of(request)
    ).pipe(
      switchMap((request) =>
        next.handle(request).pipe(
          catchError((error) => {
            return this.handleResponseError(error, request, next);
          })
        )
      )
    );
  }

  addAuthHeader(
    request: HttpRequest<any>,
    newToken?: string
  ): HttpRequest<any> {
    let token = newToken;
    token =
      token ||
      JSON.parse(this.localStorage.getItem('rspl-user') || '{}')?.authorization
        ?.auth;

    if (token && request.url !== this.config.urls.baseUrl + `/users/sign_in`) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json;odata=verbose',
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json;odata=verbose',
        },
      });
    }
    return request;
  }

  handleResponseError(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Invalid token error
    if (
      !request.url.includes('/users/sign_out') &&
      err.error.code === ErrorCode.invalid_token_error &&
      !!JSON.parse(this.localStorage.getItem('rspl-user') || '{}')
        ?.authorization?.auth
    ) {
      this.tabsChannel.postMessage({ type: 'REFRESH_TOKEN' });
      this.refreshInProgress = true;
      this.refreshToken();
      return this.tokenRefreshed$.pipe(
        switchMap((newToken) => {
          let newReq = request.clone();
          newReq = this.addAuthHeader(newReq, newToken);
          return next.handle(newReq).pipe(
            finalize(() => {
              dispatchEvent(new Event('reduce_loader_req'));
            })
          );
        })
      );
    }
    const time = 10000;
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.

      // show error notification
      this.snackBar.open(err.error.message, 'x', {
        duration: time,
      });
    } else {
      let message = '';
      if (err.error.code === ErrorCode.record_not_found_error) {
        message += 'Page Not Found';
      } else if (err.error.code === ErrorCode.access_denied_error) {
        message += 'Access Not Allowed';
      } else {
        message +=
          err.error && err.error.message ? ' ' + err.error.message : '';
        message +=
          err.error && err.error.exceptionMessage
            ? ' ' + err.error.exceptionMessage
            : ' - ';
        message += err.statusText;
      }
      // show error notification
      this.snackBar.open(`Error: ${err.status} ${message}`, 'x', {
        duration: time,
      });
    }
    if (
      request.url.includes('/users/refresh') ||
      request.url.includes('/users/sign_out') ||
      err.error.code === ErrorCode.invalid_token_error
    ) {
      this.logout();
    } else if (
      [
        ErrorCode.record_not_found_error,
        ErrorCode.access_denied_error,
      ].includes(err.error.code)
    ) {
      this.router.navigate(this.config.defaultRoute);
    }

    return throwError(() => new Error(err));
  }

  async refreshToken() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    if (this.masterTab === this.tabId && this.refreshInProgress) {
      this.refreshTimeout = setTimeout(() => {
        navigator.locks.request('refresh_token', { ifAvailable: true }, () => {
          this.authService
            .refreshToken()
            .pipe(
              catchError(() => {
                this.logout();
                this.tabsChannel.postMessage({
                  type: 'REFRESH_TOKEN_FAILED',
                });
                return throwError(
                  () => new Error('refreshed token req failed')
                );
              })
            )
            .subscribe((authData) => {
              this.zone.run(() => {
                this.tabsChannel.postMessage({
                  type: 'REFRESH_TOKEN_FINISHED',
                  token: authData.authorization.auth,
                });
                if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
                this.refreshInProgress = false;
                if (authData.authorization.auth)
                  this.tokenRefreshedSource.next(authData.authorization.auth);
              });
            });
        });
      }, 1000);
    }
  }

  logout() {
    this.authService.logout(false);
  }

  setLocation(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (!['GET', 'OPTIONS'].includes(request.method)) {
      if (
        this.location &&
        this.locationTime?.add(5, 'minutes')?.isAfter(moment())
      ) {
        request = request.clone({
          setParams: {
            ...request.params.getAll,
            'current_location[lat]': this.location.latitude.toString(),
            'current_location[lng]': this.location.longitude.toString(),
          },
        });
        return of(request);
      }
      return this.getLocation().pipe(
        switchMap((position) => {
          this.location = position.coords;
          this.locationTime = moment();
          request = request.clone({
            setParams: {
              ...request.params.getAll,
              'current_location[lat]': position.coords.latitude,
              'current_location[lng]': position.coords.longitude,
            },
          });
          return of(request);
        }),
        catchError((err) => {
          if (this.config.buildType === BuildType.DEV)
            this.snackBar.open("Location couldn't be detected", 'x', {
              duration: 5000,
            });
          return of(request);
        })
      );
    } else {
      return of(request);
    }
  }

  getLocation(): Observable<any> {
    return new Observable((observer) => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position);
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  }

  getUniqueId(): string {
    const stringArr = [];
    for (let i = 0; i < 5; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryConfig, HistoryEntity, HISTORY_CONFIGURATION } from '@rspl-ui';

@Component({
  selector: 'app-schedule-history',
  templateUrl: './schedule-history.component.html',
  styleUrls: ['./schedule-history.component.scss'],
})
export class ScheduleHistoryComponent implements OnInit {
  config?: HistoryConfig;

  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.config = {
      itemIds: [this.route.snapshot.params.id],
      ...HISTORY_CONFIGURATION[HistoryEntity.SCHEDULE]
    };
  }
}

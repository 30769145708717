import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PhoneConfigurationService } from '@rspl-api';
import * as fromActions from './phone-configuration.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable()
export class PhoneConfigurationEffects {

  createPhoneConfiguration = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createPhoneConfiguration),
      mergeMap((payload) =>
        this.service.create(payload.phoneConfiguration)
          .pipe(
            map((result) => {
                return fromActions.createPhoneConfigurationSuccess({
                  phoneConfiguration: result
                });
              }
            ),
            catchError(() => [fromActions.createPhoneConfigurationFail()])
          )
      )
    );
  });

  updatePhoneConfiguration = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updatePhoneConfiguration),
      mergeMap((payload) =>
        this.service.update(payload.phoneConfiguration.id, payload.phoneConfiguration)
          .pipe(
            map((result) => {
                return fromActions.updatePhoneConfigurationSuccess({
                  phoneConfiguration: result
                });
              }
            ),
            catchError(() => [fromActions.updatePhoneConfigurationFail()])
          )
      )
    );
  });

  getPhoneConfigurations = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPhoneConfigurations),
      switchMap((params) =>
        this.service.filter(params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getPhoneConfigurationsSuccess({
                  phoneConfigurations: results.results,
                  resultsCount: results.totalResults,
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getPhoneConfiguration = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPhoneConfiguration),
      switchMap((params) =>
        this.service.find(params.phoneConfigurationId)
          .pipe(
            map((result) => {
                return fromActions.getPhoneConfigurationSuccess({
                  phoneConfiguration: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  deletePhoneConfiguration = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deletePhoneConfiguration),
      switchMap((params) =>
        this.service.delete(params.phoneConfigurationId)
          .pipe(
            map((result) => {
                return fromActions.deletePhoneConfigurationSuccess({
                  phoneConfigurationId: params.phoneConfigurationId
                });
              }
            ),
            catchError(() => [fromActions.deletePhoneConfigurationFail()])
          )
      )
    );
  });

  restorePhoneConfiguration = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restorePhoneConfiguration),
      switchMap((params) =>
        this.service.restore(params.phoneConfigurationId)
          .pipe(
            map((result) => {
                return fromActions.restorePhoneConfigurationSuccess({
                  phoneConfigurationId: params.phoneConfigurationId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: PhoneConfigurationService
  ) {
  }
}

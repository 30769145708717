import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JourneysService } from '@rspl-api';
import * as fromActions from './journey.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';


@Injectable()
export class JourneysEffects {

  getJourney = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getJourney),
      switchMap((params) =>
        this.service.getJourney(params.findParams)
          .pipe(
            map((result) => {
                return fromActions.getJourneySuccess({
                  journey: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });


  constructor(
    private actions$: Actions,
    private service: JourneysService,
  ) {
  }
}

<button id="print-button"
        style="display: none"
        printSectionId="pdf"
        ngxPrint
        [useExistingCss]="true"
        printTitle="Tax Receipt {{donation?.donationCode}}"
></button>
<form [formGroup]="form"></form>
<div *ngIf="donation && !charity" class="not-available">
  <p>Tax receipt is not available yet!</p>
</div>
<div id="pdf" *ngIf="charity && form">
  <div class="report-wrapper">
    <div fxLayout="column" fxLayoutGap="0px">
      <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
        <div fxLayout="column" fxFlex class="img-wrapper" fxFlex.gt-xs="33" fxLayoutAlign="center center"
             [ngClass]="{'background' : !charity.taxReceipt?.logo && !charity.logo}">
          <img *ngIf="charity?.taxReceipt?.logo || charity?.logo" [src]="charity.taxReceipt?.logo || charity.logo"
               alt="Logo">
          <span *ngIf="!charity?.taxReceipt?.logo && !charity?.logo">Logo</span>
        </div>
        <div fxLayout="row" fxFlex.gt-xs="67">
        <div fxLayout="column" fxFlex fxLayoutAlign="start end"  fxLayoutAlign.xs="center start">
          Tax Receipt Number: <br/> #{{donation?.donationCode || 'RS-0001'}}
        </div>
        <div fxLayout="column" fxFlex fxLayoutAlign="start end"  fxLayoutAlign.xs="center end">
          Tax I.D. Number: <br/> {{charity.ein}}
        </div>
      </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
        <div fxLayout="column" fxFlex="80" fxLayoutAlign="end start"  fxLayoutAlign.lt-md="center start">
          <h1>Thank you for your donation!</h1>
        </div>
        <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" class="text-center">
          <p>Please click <a target="_blank" href="https://www.irs.gov/forms-pubs/about-publication-561">here</a> to see the IRS guide on donation value and
            exemptions</p>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-lg="column" fxFlex="100" class="info-row">
        <div fxLayout="column" fxFlex="50">
          <p><b>Donor Name:</b> {{donation?.donor?.name}}</p>
        </div>
        <div fxLayout="column" fxFlex="50">
          <p><b>Date:</b> {{donation?.date | date : 'MMM d, y'}}</p>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" class="info-row">
        <div fxLayout="column">
          <p><b>Charity:</b> {{charity.name}}</p>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" class="separator info-row">
        <div fxLayout="column">
          <p><b>Charity Address:</b> {{charity.fullAddress}}</p>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100">
        <div fxLayout="column" class="donation-items">
          <h2>Donation Items:</h2>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" class="underline">
        <div fxLayout="column" fxFlex="50">
          <h2>Item Name</h2>
        </div>
        <div fxLayout="column" fxFlex="25" fxFlexOffset="25" *ngIf="canEdit">
          <h2>Estimated Value</h2>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" *ngFor="let group of items; let i = index">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="70">
          <mat-form-field appearance="outline">
            <mat-label>{{labels[i]}}</mat-label>
            <input
              [formControl]="getControl(group, 'itemName')"
              matInput
              [readonly]="!canEdit && !donation">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="25" fxFlexOffset="25" fxFlex.xs="25" fxFlexOffset.xs="5" *ngIf="canEdit || !donation">
          <mat-form-field appearance="outline">
            <input
              [formControl]="getControl(group, 'estimatedValue')"
              matInput
              onchange="value = Math.abs(value)"
              (input)="updateTotal()"
              type="number"
              [readonly]="!canEdit"
            >
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" *ngIf="canEdit || !donation" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="75" fxLayoutAlign="center end">
          <h2>Estimated Total:</h2>
        </div>
        <div fxLayout="column" fxFlex="25" [ngClass]="{'error': total.value > 5000}">
          <mat-form-field appearance="outline">
            <input matInput [formControl]="total" readonly type="number">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start end" class="disclaimer">
        <p style="white-space: pre-line;">{{charity.taxReceipt?.disclaimer}}</p>
      </div>
    </div>
  </div>
</div>

<form
  [formGroup]="form"
  fxLayout="column"
  fxLayoutGap="30px"
  *ngIf="form"
  class="schedule-form"
>
  <div fxLayout="column" fxLayoutGap="5px" *ngIf="isCaptain && config.parentType === 'Truck'">
    <mat-label>Block availability for:</mat-label>
    <div>
      <mat-button-toggle-group>
        <mat-button-toggle
          *ngFor="let lt of leadTimes"
          [checked]="leadTime.value === lt"
          (click)="setLeadTime(lt)"
        >
          {{ lt }}h
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <rspl-info-box
    type="warning"
    *ngIf="fetched && !schedule.id && isDefault && showWarning"
  >
    {{
      config.parentType === "Truck"
        ? "Your truck is not ready for pickups"
        : "Your store is not ready to receive donations"
    }}
    , at least the default schedule needs to be configured!
  </rspl-info-box>
  <div fxLayout="row" fxLayoutGap="10px" class="days-wrapper">
    <rspl-card
      *ngFor="let d of showDays; let i = index"
      fxLayout="column"
      fxFlex
      [cardTitle]="getTitle(d)"
      class="no-padding"
    >
      <div
        fxLayout="row"
        [ngClass]="{ separator: !config.showTime && config.showCapacity }"
      >
        <mat-label ngClass="labelBeforeSlide">Open</mat-label>
        <mat-slide-toggle
          #daySlideToggle
          labelPosition="before"
          [checked]="!fetched || !schedule[d + 'Time'].closed"
          *ngIf="fetched"
          (change)="dayToggle(d, !$event.checked)"
        ></mat-slide-toggle>
        <mat-slide-toggle
          labelPosition="before"
          [checked]="false"
          *ngIf="!fetched"
        ></mat-slide-toggle>
      </div>
      <div fxLayout="row" *ngIf="config.showTime" class="separator">
        <mat-form-field appearance="outline" fxLayout="column" fxFlex="47">
          <mat-label>From:</mat-label>
          <mat-select matInput [formControl]="getOpensControl(d + 'Time')">
            <mat-option *ngFor="let h of hours" [value]="h.value">
              {{ h.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxLayout="column" fxFlex="6" fxLayoutAlign="center center">
          <span class="time-separator">-</span>
        </div>
        <mat-form-field appearance="outline" fxLayout="column" fxFlex="47">
          <mat-label>To:</mat-label>
          <mat-select matInput [formControl]="getClosesControl(d + 'Time')">
            <mat-option *ngFor="let h of hours" [value]="h.value">
              {{ h.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" *ngIf="config.showCapacity">
        <h3 [ngClass]="{ disabled: getClosedControl(d + 'Time').value }">
          Capacity
        </h3>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="5px"
        *ngIf="config.showCapacity"
        [ngClass]="{ separator: config.zonesPerDay }"
      >
        <mat-form-field appearance="outline" fxLayout="column" fxFlex="50">
          <mat-label>AM:</mat-label>
          <input
            matInput
            type="number"
            [formControl]="getAmCapacityControl(d + 'Capacity')"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" fxLayout="column" fxFlex="50">
          <mat-label>PM:</mat-label>
          <input
            matInput
            type="number"
            [formControl]="getPmCapacityControl(d + 'Capacity')"
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" *ngIf="config.zonesPerDay">
        <button
          [disabled]="getClosedControl(d + 'Time').value || !fetched"
          mat-raised-button
          class="text-center full-width primary-btn filled-btn zone-btn"
          (click)="selectDay(d)"
          [ngClass]="{
            'only-zone': true,
            active: selectedDay === d,
            error:
              fetched &&
              !getClosedControl(d + 'Time').value &&
              !(getDayZips(d)?.length > 0)
          }"
        >
          ZONE
          <span
            class="zip-warning"
            *ngIf="
              fetched &&
              !getClosedControl(d + 'Time').value &&
              !(getDayZips(d)?.length > 0)
            "
          >
            <span
              [matTooltipShowDelay]="1000"
              matTooltip="Zones are required"
              class="mat-icon notranslate material-icons mat-warn"
            >
              warning
            </span>
          </span>
        </button>
      </div>
    </rspl-card>
  </div>
  <div
    class="zone-wrapper"
    fxLayout="row"
    fxLayoutGap="30px"
    fxFill
    [ngClass]="{ hidden: !!day && getClosedControl(day + 'Time').value }"
  >
    <rspl-card
      [cardTitle]="(config.zonesPerDay ? getTitle(selectedDay) : '') + ' Zone'"
      fxLayout="column"
      fxLayoutGap="30px"
      fxFill
    >
      <button
        *ngIf="zones?.length > 0"
        class="primary-btn select-zone"
        mat-raised-button
        [disabled]="!fetched"
        [matMenuTriggerFor]="zipSetsDropdown"
        (menuOpened)="zonesOpened(zoneFilter, 50)"
      >
        Choose from saved Zones
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #zipSetsDropdown="matMenu">
        <mat-form-field appearance="outline" class="zone-filter">
          <input
            matInput
            #zoneFilter
            (click)="$event.stopPropagation()"
            (input)="filterZones(zoneFilter.value, zoneFilter)"
          />
        </mat-form-field>
        <button
          mat-menu-item
          *ngFor="let zone of filteredZones"
          (click)="zoneFilter.value = ''; useZone(zone)"
        >
          {{ zone.name }}
        </button>
      </mat-menu>
      <app-zip-picker
        *ngFor="let d of showDays"
        [hidden]="d !== selectedDay"
        [day]="d"
        #zone
        fxFill
        fxLayoutGap="30px"
        [disabled]="!fetched"
        (scheduleUpdated)="updatedZips($event)"
      ></app-zip-picker>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        style="margin-bottom: 30px"
        fxLayoutGap="20px"
        fxLayout.xs="column"
      >
        <!-- <button
          class="primary-color"
          mat-button
          ngClass.xs="btn-block"
          [disabled]="
            !fetched ||
            !(
              getZoneGroup(selectedDay + 'Zone')?.get('amZips')?.value?.length >
              0
            )
          "
          (click)="saveZone()"
        >
          Save Zone for future use
        </button> -->
        <button
          class="primary-btn"
          ngClass.xs="btn-block"
          mat-raised-button
          [disabled]="!fetched"
          (click)="updatedZips([])"
        >
          Clear all
        </button>
        <button
          class="primary-btn filled-btn"
          ngClass.xs="btn-block"
          mat-raised-button
          [disabled]="!fetched"
          rsplDisableDoubleClick
          (oneClick)="submitForm()"
        >
          Save
        </button>
      </div>
      <rspl-map
        [showZips]="true"
        *ngIf="fetched && windowSize !== windowSizes.XS && showMap"
        [selectedZips]="
          getZoneGroup(selectedDay + 'Zone')?.get('amZips')?.value || []
        "
        (zipSelectionUpdated)="updatedZips($event)"
        [disabled]="!fetched"
        [showAllZips]="true"
        [hoverableZips]="true"
        [selectableZips]="true"
        >>
      </rspl-map>
    </rspl-card>
  </div>
</form>

<button
  [class]="'theme-icon app-' + app"
  [ngClass]="{ auto: autoTheme }"
  (click)="toggleTheme()"
  mat-icon-button
  [matTooltip]="
    autoTheme
      ? 'Use System Theme'
      : theme === themes.LIGHT
      ? 'Light Theme'
      : 'Dark Theme'
  "
  [matTooltipShowDelay]="500"
>
  <mat-icon>invert_colors</mat-icon>
  <mat-icon class="auto-theme" *ngIf="autoTheme">hdr_auto</mat-icon>
</button>

<mat-form-field appearance="outline" fxFlex="100" class="pick-charity">
  <mat-label>Select Stores</mat-label>
  <mat-icon matPrefix [ngStyle]="{ opacity: disabled ? 0.5 : 1 }"
    >search</mat-icon
  >
  <mat-select
    matInput
    [(value)]="selected"
    (selectionChange)="charityStoresSelected($event.value)"
    panelClass="pick-charity"
    disableOptionCentering="true"
    [disabled]="disabled"
    multiple
    (openedChange)="focusFilter(filterInput, $event)"
  >
    <input
      type="text"
      placeholder="Search..."
      matInput
      class="picker-filter"
      autocomplete="off"
      name="charity-picker-filter"
      #filterInput
      [formControl]="filter"
      (input)="filterDelayed()"
      (keydown.space)="$event.stopPropagation()"
    />
    <button
      *ngIf="visibleCharityStores.length > 0"
      mat-button
      class="select-all btn-block"
      (click)="toggleAll()"
    >
      {{
        selected.length !== visibleCharityStores.length
          ? "Select All"
          : "Deselect All"
      }}
    </button>
    <ng-container *ngFor="let marketId of marketIds">
      <div *ngIf="marketsCount === 0 || markets[marketId]">
        <p *ngIf="markets[marketId] && charityIds[marketId].length > 0" class="market">
          <mat-checkbox
            [checked]="isMarketSelected(marketId)"
            (change)="toggleMarket(marketId)"
          >
            {{
              markets && markets[marketId]
                ? markets[marketId].name
                : "Unassigned"
            }}
          </mat-checkbox>
        </p>
        <div *ngFor="let charityId of charityIds[marketId]">
          <p
            *ngIf="charityStoreIds[marketId][charityId].length > 0"
            class="charity"
          >
            <mat-checkbox
              [checked]="isCharitySelected(marketId, charityId)"
              (change)="toggleCharity(marketId, charityId)"
            >
              {{
                charities && charities[charityId]
                  ? charities[charityId].name
                  : ""
              }}
            </mat-checkbox>
          </p>
          <mat-option
            *ngFor="let option of charityStoreIds[marketId][charityId]"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </div>
      </div>
    </ng-container>
  </mat-select>
  <mat-icon *ngIf="selected.length > 0" matSuffix (click)="clear($event)"
    >close</mat-icon
  >
</mat-form-field>

import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DONOR_CPT_CANCEL_REASONS, Donation, ENVIRONMENT, Environment, PARTNER_CANCEL_REASONS, PARTNER_CPT_CANCEL_REASONS, Reason } from '@domains';
import * as moment from 'moment';

@Component({
  selector: 'rspl-cancel-donation-dialog',
  templateUrl: './cancel-donation-dialog.component.html',
  styleUrls: ['./cancel-donation-dialog.component.scss']
})
export class CancelDonationDialogComponent {
  reason: FormControl<Reason>;
  subReason: FormControl<string>;
  selectedReason: Reason;
  additionalText: FormControl<string>;
  sameDay: boolean;
  asDonor = false;
  chargeCancellation = false;
  bookingFeeRefund = true;
  isCaptain: boolean;
  reasons: Reason[];

  public constructor(
    public reference: MatDialogRef<CancelDonationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Donation,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    this.isCaptain = this.environment.isCaptain;
    this.reason = new FormControl<Reason>(null, Validators.required);
    this.subReason = new FormControl<string>(null, Validators.required);
    this.additionalText = new FormControl<string>(null, Validators.required);
    this.sameDay = moment(data.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

    this.reasons = this.isCaptain ? PARTNER_CPT_CANCEL_REASONS : PARTNER_CANCEL_REASONS;
  }

  public onChoice(choice: boolean): void {
    let reason = '';
    let note = '';
    if (choice) {
      this.reason.updateValueAndValidity();
      this.subReason.updateValueAndValidity();
      this.reason.markAllAsTouched();
      this.subReason.markAllAsTouched();
      this.additionalText.updateValueAndValidity();
      this.additionalText.markAllAsTouched();
      if (this.reason.invalid || this.subReason.invalid || this.additionalText.invalid) {
        return;
      }

      reason = (this.sameDay ? '[Same Day] ' : '[Prior Notice] ') + this.reason.value.reason + (this.subReason.value ? (' : ' + this.subReason.value) : '');
      note = this.additionalText.value;
    }
    
    this.reference.close(choice ? {
      reason,
      note,
      asDonor: this.asDonor,
      ...(this.asDonor ? {chargeCancellation: this.chargeCancellation} : {}),
      ...(!this.asDonor && this.data.payment.bookingIntent ? {bookingFeeRefund: this.bookingFeeRefund} : {})
    } : null);
  }

  setSelectedReason(): void {
    this.selectedReason = this.reason.value;
    if (this.selectedReason.children) {
      this.subReason.setValidators(Validators.required);
      this.additionalText.clearValidators();
      this.subReason.updateValueAndValidity();
      this.additionalText.updateValueAndValidity();
    } else {
      this.subReason.clearValidators();
      this.additionalText.setValidators(Validators.required);
      this.additionalText.updateValueAndValidity();
    }
  }

  asDonorChange(): void {
    if (this.asDonor) {
      this.reasons = DONOR_CPT_CANCEL_REASONS;
    } else {
      this.reasons = this.isCaptain ? PARTNER_CPT_CANCEL_REASONS : PARTNER_CANCEL_REASONS;
    }
  }
}

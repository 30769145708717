import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ScheduleHistoryService } from '@rspl-api';
import * as fromActions from './schedule-history.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';


@Injectable()
export class ScheduleHistoryEffects {


  getHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getScheduleHistory),
      switchMap((params) =>
        this.service.history(params.itemIds)
          .pipe(
            map((result) => {
                return fromActions.getScheduleHistorySuccess({
                  history: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: ScheduleHistoryService
  ) {
  }
}

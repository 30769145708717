import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiTemplateComponent } from '@core';

const routes: Routes = [
  {
    path: '',
    component: UiTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@zendesk-donations').then(
            (module) => module.ZendeskDonationsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

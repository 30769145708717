import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Item } from '@domains';
import { ItemService } from '@rspl-api';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-item-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
})
export class ItemLookupComponent extends LookupComponent<Item> {
  override formatLabel?: (item: Item) => string = (item: Item) =>
    item.approved
      ? item.meta.createdByCaptain
        ? `${item.name} (by captain)`
        : item.name
      : `${item.name} (Not Approved)`;
  constructor(
    protected override apiService: ItemService,
    protected override dialog: MatDialog
  ) {
    super(apiService, dialog);
    this.filters = {approved: true}
  }
}

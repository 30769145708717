import { createFeatureSelector, createSelector } from '@ngrx/store';
import { XmileState } from './xmile.reducers';

// tslint:disable: max-line-length
export const xmilesFeatureStateSelector = createFeatureSelector<XmileState>('xmile');

export const selectXmiles = createSelector(xmilesFeatureStateSelector, (state: XmileState) => state.xmiles);
export const selectXmilesTotalCount = createSelector(xmilesFeatureStateSelector, (state: XmileState) => state.xmilesTotalCount);

export const selectXmile = createSelector(xmilesFeatureStateSelector, (state: XmileState) => state.xmile);

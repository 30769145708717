import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
  providers: [],
})
export class RsplApiModule {
  public static forRoot(): ModuleWithProviders<RsplApiModule> {
    return {
      ngModule: RsplApiModule,
      providers: [RsplApiModule],
    };
  }
}

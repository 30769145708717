<div fxLayout="row" class="week-picker" fxLayoutAlign="center center">
  <button mat-button (click)="moveWeek(-1)">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <p fxLayout="column" fxFlex fxLayoutAlign="center center">{{weekStart.toDate() | date:'MMM d, y'}}
    - {{weekEnd.toDate() | date:'MMM d, y'}}</p>
  <button mat-button (click)="moveWeek(1)">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>

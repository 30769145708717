import { FindParams, Market } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum MarketActionType {
  CreateMarket = '[Market] Create Market',
  CreateMarketSuccess = '[Market] Create Market Success',

  UpdateMarket = '[Market] Update Market',
  UpdateMarketSuccess = '[Market] Update Market Success',

  ClearMarketsFindParams = '[Charity] Clear Markets Find Params',
  GetMarkets = '[Market] Get Markets',
  GetMarketsSuccess = '[Market] Get Markets Success',

  GetMarket = '[Market] Get Market',
  GetMarketSuccess = '[Market] Get Market Success',

  DeleteMarket = '[Market] Delete Market',
  DeleteMarketSuccess = '[Market] Delete Market Success',

  RestoreMarket = '[Market] Restore Market',
  RestoreMarketSuccess = '[Market] Restore Market Success',

  ClearMarketsForFeatureFlagsFindParams = '[Captain] Clear Markets For Feature Flags Find Params',
  GetMarketsForFeatureFlags = '[Market] Get Markets For Feature Flags',
  GetMarketsForFeatureFlagsSuccess = '[Market] Get Markets For Feature Flags Success',

  UpdateForFeatureFlagsMarket = '[Market] Update For Feature Flags Market',
  UpdateForFeatureFlagsMarketSuccess = '[Market] Update For Feature Flags Market Success',
  UpdateForFeatureFlagsMarketFail = '[Market] Update For Feature Flags Market Fail',
}

export const createMarket = createAction(MarketActionType.CreateMarket, props<{ market: Market }>());
export const createMarketSuccess = createAction(MarketActionType.CreateMarketSuccess, props<{ market: Market }>());

export const updateMarket = createAction(MarketActionType.UpdateMarket, props<{ market: Market }>());
export const updateMarketSuccess = createAction(MarketActionType.UpdateMarketSuccess, props<{ market: Market }>());

export const clearMarketsFindParams = createAction(MarketActionType.ClearMarketsFindParams);
export const getMarkets = createAction(MarketActionType.GetMarkets, props<{ findParams: FindParams }>());
export const getMarketsSuccess = createAction(MarketActionType.GetMarketsSuccess, props<{ markets: Array<Market>, resultsCount: number }>());

export const getMarket = createAction(MarketActionType.GetMarket, props<{ id: string, keep?: boolean }>());
export const getMarketSuccess = createAction(MarketActionType.GetMarketSuccess, props<{ market: Market }>());

export const deleteMarket = createAction(MarketActionType.DeleteMarket, props<{ marketId: string }>());
export const deleteMarketSuccess = createAction(MarketActionType.DeleteMarketSuccess, props<{ marketId: string }>());

export const restoreMarket = createAction(MarketActionType.RestoreMarket, props<{ marketId: string }>());
export const restoreMarketSuccess = createAction(MarketActionType.RestoreMarketSuccess, props<{ marketId: string }>());

export const clearMarketsForFeatureFlagsFindParams = createAction(MarketActionType.ClearMarketsForFeatureFlagsFindParams);
export const getMarketsForFeatureFlags = createAction(MarketActionType.GetMarketsForFeatureFlags, props<{ findParams: FindParams, keep?: boolean }>());
export const getMarketsForFeatureFlagsSuccess = createAction(MarketActionType.GetMarketsForFeatureFlagsSuccess, props<{
  resultsCount: number,
  results: Array<Market>
}>());

export const updateForFeatureFlagsMarket = createAction(MarketActionType.UpdateForFeatureFlagsMarket, props<{ market: Market }>());
export const updateForFeatureFlagsMarketSuccess = createAction(MarketActionType.UpdateForFeatureFlagsMarketSuccess, props<{ market: Market }>());
export const updateForFeatureFlagsMarketFail = createAction(MarketActionType.UpdateForFeatureFlagsMarketFail, props<{ market: Market }>());

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  AppType,
  Environment,
  ENVIRONMENT,
  Market,
  Partner,
  Truck,
} from '@domains';
import { Destructible } from '@rspl-ui';
import { getTrucks, getTrucksSuccess } from '@core';
import { selectTrucks } from '@core';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pick-trucks',
  templateUrl: './pick-trucks.component.html',
  styleUrls: ['./pick-trucks.component.scss'],
})
export class PickTrucksComponent extends Destructible implements OnInit {
  timeout?: any;
  isCaptain: boolean;

  // tslint:disable-next-line:variable-name
  _currentTruckIds: string[] = [];
  @Output() selectedTrucks = new EventEmitter();

  markets: { [key: string]: Market } = {};

  @Input() set marketsList(marketsList: Market[]) {
    this.markets = {};
    marketsList?.forEach((m) => {
      if (m.id?.toString()) this.markets[m.id?.toString()] = m;
    });
  }
  partners: { [key: string]: Partner } = {};

  @Input()
  set currentTruckIds(currentTruckIds: string[]) {
    this._currentTruckIds = currentTruckIds;
    this.setTrucks();
  }

  get currentTruckIds(): string[] {
    return this._currentTruckIds;
  }

  @Input() disabled = false;
  @Input() multiple = true;
  @Input() required = false;
  @Input() showError = false;
  @Input() partnerIdRequired = false;
  @Input() set partnerId(partnerId: string) {
    if (partnerId)
      this.store.dispatch(
        getTrucks({
          findParams: {
            'expand[]': ['partner'],
            per_page: 1000,
            partner_id: partnerId,
          },
        })
      );
  }

  selected: any = [];
  filter = new FormControl<string>('');

  public trucks$!: Observable<Truck[]>;
  public trucks: Array<Truck> = [];
  public truckGroups: {
    [key: string]: {
      [key: string]: Array<Truck>;
    };
  } = {};
  // tslint:disable-next-line:variable-name
  _selectedMarkets: Array<string> = [];
  @Input() set selectedMarkets(selectedMarkets: Array<string>) {
    this._selectedMarkets = selectedMarkets?.map((id) => id?.toString());
    this.marketUpdated();
    this._filter(this.filter.value, this.trucks);
  }

  get selectedMarkets(): Array<string> {
    return this._selectedMarkets;
  }

  truckIds: {
    [key: string]: {
      [key: string]: Array<Truck>;
    };
  } = {};
  marketIds: string[] = [];
  partnerIds: { [key: string]: Array<string> } = {};
  filteredOptions?: Observable<{
    [key: string]: {
      [key: string]: Array<Truck>;
    };
  }>;
  visibleTrucks: Array<Truck> = new Array<Truck>();
  filterTimeout?: any;

  constructor(
    private store: Store,
    private actions: Actions,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super();
    this.isCaptain = environment.app === AppType.CAPTAIN;
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(getTrucksSuccess), take(1)).subscribe(() => {
      this.trucks$ = this.store.pipe(
        select(selectTrucks),
        takeUntil(this.destroy$)
      );
      this.trucks$.subscribe((trucks) => {
        this.trucks = trucks;
        this.groupTrucks();
        this.setTrucks();
        this._filter(this.filter.value, this.trucks);
      });
    });
    if (!this.partnerIdRequired) {
      this.store.dispatch(
        getTrucks({ findParams: { 'expand[]': ['partner'], per_page: 1000 } })
      );
    }
  }

  private setTrucks(): void {
    if (this.currentTruckIds.length > 0 && this.trucks?.length) {
      this.selected = this.multiple
        ? [...this.currentTruckIds]
        : this.currentTruckIds[0];
    } else {
      this.selected = this.multiple ? [] : null;
    }
  }

  private _filter(
    value: string | null,
    trucks: Array<Truck>
  ): {
    [key: string]: {
      [key: string]: Array<Truck>;
    };
  } {
    let res: Array<Truck>;
    if (value) {
      const filterValue = value.toLowerCase();
      res = [...(trucks || [])].filter(
        (option) =>
          (this.selectedMarkets?.length === 0 ||
            (!!option.partner?.marketId &&
              this.selectedMarkets?.includes(
                option.partner.marketId?.toString()
              ))) &&
          (option.name.toLowerCase().includes(filterValue) ||
            option.partner?.name.toLowerCase().includes(filterValue) ||
            (option.partner?.marketId &&
              this.markets[option.partner.marketId?.toString()]?.name
                .toLowerCase()
                .includes(filterValue)))
      );
    } else {
      res = [...(trucks || [])].filter(
        (option) =>
          this.selectedMarkets?.length === 0 ||
          (!!option.partner?.marketId &&
            this.selectedMarkets?.includes(option.partner.marketId?.toString()))
      );
    }
    this.truckIds = {};
    this.partnerIds = {};
    res?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    res.forEach((t: Truck) => {
        if (!this.truckIds[t.partner.marketId?.toString()]) {
          this.truckIds[t.partner.marketId?.toString()] = {};
        }
        if (!this.truckIds[t.partner.marketId?.toString()][t.partner.id]) {
          this.truckIds[t.partner.marketId?.toString()][t.partner.id] =
            new Array<Truck>();
        }
        this.truckIds[t.partner.marketId?.toString()][t.partner.id].push(t);
    });
    Object.keys(this.truckIds).forEach((market) =>
      Object.keys(this.truckIds[market]).forEach((partner) =>
        this.truckIds[market][partner].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      )
    );
    this.marketIds = Object.keys(this.truckIds).sort((m1, m2) =>
      this.markets[m1]?.name > this.markets[m2]?.name
        ? 1
        : this.markets[m2]?.name > this.markets[m1]?.name
        ? -1
        : 0
    );
    Object.keys(this.truckIds || {}).forEach(
      (marketId) =>
        (this.partnerIds[marketId] = Object.keys(this.truckIds[marketId]).sort(
          (p1, p2) =>
            this.partners[p1].name > this.partners[p2].name
              ? 1
              : this.partners[p2].name > this.partners[p1].name
              ? -1
              : 0
        ))
    );

    this.visibleTrucks = new Array<Truck>();
    Object.keys(this.truckIds).forEach((m) => {
      Object.keys(this.truckIds[m]).forEach((p) => {
        this.truckIds[m][p].forEach((x) => this.visibleTrucks.push(x));
      });
    });
    return this.truckIds;
  }

  public trucksSelected(value?: any): void {
    this.selected = this.multiple ? [...(value || [])] : value;
    this.selectedTrucks.emit(this.selected);
  }

  clear(event: MouseEvent): void {
    this.selected = this.multiple ? [] : null;
    this.trucksSelected();
    event.stopPropagation();
  }

  focusFilter(filterInput: HTMLInputElement, $event: boolean): void {
    if ($event) {
      filterInput.focus();
    } else {
      this.filter.setValue('');
      this._filter(this.filter.value, this.trucks);
    }
  }

  private groupTrucks(): void {
    this.truckGroups = {};
    this.partners = {};
    this.trucks.forEach((t) => {
        this.truckGroups[t.partner.marketId?.toString()] =
          this.truckGroups[t.partner.marketId?.toString()] || {};
        this.truckGroups[t.partner.marketId?.toString()][t.partner.id] =
          this.truckGroups[t.partner.marketId?.toString()][t.partner.id] || [];
        this.truckGroups[t.partner.marketId?.toString()][t.partner.id].push(t);
        this.partners[t.partner.id] = t.partner;
    });
  }

  private marketUpdated(): void {
    if (this.selectedMarkets?.length > 0) {
      this.trucksSelected([
        ...(this.trucks || [])
          .filter(
            (t) =>
              t.partner?.marketId &&
              this.selectedMarkets?.includes(t.partner.marketId?.toString())
          )
          .map((t) => t.id),
      ]);
    } else {
      this.trucksSelected();
    }
  }

  toggleAll(): void {
    const visibleTrucks = this.visibleTrucks;
    if (this.selected.length !== visibleTrucks.length) {
      this.trucksSelected(visibleTrucks.map((x) => x.id));
    } else {
      this.trucksSelected();
    }
  }

  isMarketSelected(marketId: string): boolean {
    return !Object.keys(this.truckGroups[marketId]).find((p) =>
      this.truckGroups[marketId][p].find((x) => !this.selected.includes(x.id))
    );
  }

  toggleMarket(marketId: string): void {
    const shouldSelect = !this.isMarketSelected(marketId);
    Object.keys(this.truckGroups[marketId]).forEach((p) =>
      this.truckGroups[marketId][p].forEach((x) => {
        if (shouldSelect && !this.selected.includes(x.id)) {
          this.selected.push(x.id);
        }
        if (!shouldSelect && this.selected.includes(x.id)) {
          this.selected.splice(this.selected.indexOf(x.id), 1);
        }
      })
    );
    this.trucksSelected(this.selected);
  }

  isPartnerSelected(marketId: string, partnerId: string): boolean {
    return !this.truckGroups[marketId][partnerId].find(
      (x) => !this.selected.includes(x.id)
    );
  }

  togglePartner(marketId: string, partnerId: string): void {
    const shouldSelect = !this.isPartnerSelected(marketId, partnerId);
    this.truckGroups[marketId][partnerId].forEach((x) => {
      if (shouldSelect && !this.selected.includes(x.id)) {
        this.selected.push(x.id);
      }
      if (!shouldSelect && this.selected.includes(x.id)) {
        this.selected.splice(this.selected.indexOf(x.id), 1);
      }
    });
    this.trucksSelected(this.selected);
  }

  filterDelayed(): void {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(
      () => this._filter(this.filter.value, this.trucks),
      150
    );
  }

  get marketsCount(): number {
    return Object.values(this.markets || {}).filter((x) => !!x).length;
  }
}

export interface PageHeaderConfig {
  scrollPercent: number;
  toolbar: {
    reduceWidthBy: number;
    left: number;
    height: number | null;
  };
  back: {
    'border-color': string;
    color: string;
  };
  title: {
    width: string;
    filter: string;
    'font-size': string;
  };
  buttons: {
    scrolled: boolean;
    showIcon: boolean;
    iconOpacity: number;
    txtOpacity: number;
    'border-color': string;
    padding: string;
    'background-color': string;
  };
}

export const DEFAULT_HEADER_POSITION = (notFixedSidebar: boolean): PageHeaderConfig => ({
  scrollPercent: 0,
  toolbar: {
    reduceWidthBy: notFixedSidebar ? 0 : 65,
    left: notFixedSidebar ? 0 : 65,
    height: 112,
  },
  back: {
    'border-color': null,
    color: null,
  },
  title: {
    width: null,
    filter: null,
    'font-size': '120%',
  },
  buttons: {
    scrolled: false,
    showIcon: false,
    iconOpacity: 0,
    txtOpacity: 1,
    'border-color': null,
    padding: '0 20px',
    'background-color': 'var(--white)',
  },
});

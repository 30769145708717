import { Action, createReducer, on } from '@ngrx/store';
import { Organization } from '@domains';
import * as fromActions from './organization.actions';

export interface OrganizationState {
  organizations: Array<Organization>;
  organizationsTotalCount: number;
  organization: Organization;
}

const initialState: OrganizationState = {
  organizations: new Array<Organization>(),
  organizationsTotalCount: 0,
  organization: new Organization(),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createOrganizationSuccess, (state, action: any) => ({...state, organization: action.organization})),
  on(fromActions.updateOrganizationSuccess, (state, action: any) => ({...state, organization: action.organization})),

  on(fromActions.getOrganizations, (state, {findParams, keep}) => {
    return {...state, organizations: keep ? state.organizations : []};
  }),
  on(fromActions.getOrganizationsSuccess, (state, {organizations, resultsCount}) => ({...state, organizations, organizationsTotalCount: resultsCount})),
  on(fromActions.clearOrganizationsFindParams, state => {
    return {...state};
  }),

  on(fromActions.getOrganization, (state, {id, keep}) => ({...state, organization: keep ? state.organization : null})),
  on(fromActions.getOrganizationSuccess, (state, action: any) => ({...state, organization: action.organization})),
);

export function organizationReducer(state: OrganizationState | undefined, action: Action) {
  return reducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { FindJourneyParams, Journey } from '@domains';

export enum JourneysActionType {
  GetJourney = '[Journey] Get Journey',
  GetJourneySuccess = '[Journey] Get Journey Success',
}

export const getJourney = createAction(JourneysActionType.GetJourney, props<{findParams: FindJourneyParams}>());
export const getJourneySuccess = createAction(JourneysActionType.GetJourneySuccess, props<{ journey: Journey }>());

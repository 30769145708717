<ng-template #PrimaryTemplate>
  <div class="priority"
       [fxFlex]="(isDesktop && windowSize !== windowSizes.XS) && config.standardEnabled ? 50 : 100"
       fxLayout="column"
       style="background: {{config.backgroundColorPrimary}}; color: {{config.textColorPrimary}}">
    <h1
      style="background: {{config?.titleBackgroundColorPrimary}}; color: {{config?.titleTextColorPrimary}}">{{config.titlePrimary}}
      <mat-icon svgIcon="prioritystar"
                style="background: {{config?.stripeBackgroundColor}}"></mat-icon>
      <span class="triangle-left"
            style="border-color: transparent {{config?.stripeBackgroundColor}} transparent transparent"></span>
      <span class="triangle-right"
            style="border-color: {{config?.stripeBackgroundColor}} transparent transparent transparent"></span>
    </h1>
    <div class="logo" fxLayout="row" fxLayoutGap="10px"
         style="background: {{config?.logoBackgroundColorPrimary}}">
      <div class="img img-priority-charity" fxFlex="40" fxLayoutAlign="end center">
        <img [src]="config.logo || charity.logo"
             style="
                   {{'margin-right: ' + ((isDesktop ? logoOffsetLeft: logoOffsetLeftMobile) * -1)}}px;
                   margin-top: {{isDesktop ? logoOffsetTop : logoOffsetTopMobile}}px;
                   height: calc({{isDesktop ? 55 : 37}}px + {{isDesktop ? logoSize : logoSizeMobile}}px);
                 "
        >
      </div>
      <span fxLayoutAlign="center center" fxFlex style="color: {{config.backgroundColorPrimary}}">
            <mat-icon>add</mat-icon>
          </span>
      <div class="img" fxFlex="40" fxLayoutAlign="start center">
        <div class="third-party-wrapper">
        <mat-icon svgIcon="rspl-new-logo"></mat-icon>
        <p *ngIf="config.version === splitScreenVersions.V2" class="third-party">3RD party veteran owned</p>
      </div>
      </div>
    </div>
    <ul *ngIf="version === splitScreenVersions.V1 && !(config.textPrimary?.trim()?.length > 0)">
      <li>
        <span class="bullet" style="background: {{config?.bulletColorPrimary}}"></span>
        Pickups as soon as 2 business days (Mon-Sat), subject to timeslot availability
      </li>
      <li>
        <span class="bullet" style="background: {{config?.bulletColorPrimary}}"></span>
        Can pick up from multiple floors
      </li>
      <li>
        <span class="bullet" style="background: {{config?.bulletColorPrimary}}"></span>
        Can disassemble furniture
      </li>
      <li>
        <span class="bullet" style="background: {{config?.bulletColorPrimary}}"></span>
        Items of ANY condition accepted
      </li>
      <li>
        <span class="bullet" style="background: {{config?.bulletColorPrimary}}"></span>
        Cost of the pickup for the charity is covered (Fee Based Service)
      </li>
    </ul>

    <ul *ngIf="version === splitScreenVersions.V1 && config.textPrimary?.trim()?.length > 0">
      <li *ngFor="let el of textPrimaryValues"><span class="bullet"
                                                     style="background: {{config?.bulletColorPrimary}}"></span>{{el}}
      </li>
    </ul>

    <ul *ngIf="version === splitScreenVersions.V2">
      <li>
        <mat-icon svgIcon="ss-primary-floor"></mat-icon>
        Pickups from any floor
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-disassembly"></mat-icon>
        Item disassembly and removal
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-items"></mat-icon>
        All items are picked up for a fee
      </li>
      <li>
        <mat-icon svgIcon="ss-primary-time"></mat-icon>
        Donation pickups in 1-3 days
      </li>
    </ul>
    <p *ngIf="version === splitScreenVersions.V2 && config.textPrimary?.trim()?.length > 0" class="standard-additional-text" [innerHTML]="textPrimaryValue">
    </p>
    <div class="btn-wrapper" fxLayout="row" fxFlex fxLayoutAlign="center end">
      <button mat-button class="btn-block"
              style="background: {{config.btnBackgroundColorPrimary}}; color: {{config.btnTextColorPrimary}}; cursor: {{config.primaryReady ? 'pointer' : 'default'}}">
            <span
              *ngIf="config.primaryReady">{{config.buttonPrimary ? config.buttonPrimary : 'Schedule Now!'}}</span>
        <span *ngIf="!config.primaryReady">Coming Soon!</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #StandardTemplate>
  <div class="free" [fxFlex]="isDesktop ? 50 : 100" fxLayout="column" *ngIf="config.standardEnabled"
       style="background: {{config.backgroundColor}}; color: {{config.textColor}}">
    <h1
      style="background: {{config?.titleBackgroundColor}}; color: {{config?.titleTextColor}}">{{config.title}}</h1>
    <div class="logo" fxLayout="row" fxLayoutAlign="center center"
         style="background: {{config?.logoBackgroundColor}}">
      <div class="img" fxLayoutAlign="center center">
        <img [src]="config.logo || charity.logo" style="
        height: calc({{isDesktop ? 55 : 37}}px + {{isDesktop ? logoStandardSize : logoStandardSizeMobile}}px);
        max-height: calc({{isDesktop ? 55 : 37}}px + {{isDesktop ? logoStandardSize : logoStandardSizeMobile}}px);
        margin-right: {{(isDesktop ? logoStandardOffsetLeft: logoStandardOffsetLeftMobile) * -1}}px;
        margin-top: {{isDesktop ? logoStandardOffsetTop : logoStandardOffsetTopMobile}}px;
        ">
      </div>
    </div>
    <ul *ngIf="version === splitScreenVersions.V1 && !(config.text?.trim()?.length > 0)">
      <li><span class="bullet" style="background: {{config?.bulletColor}}"></span>Pickup services are free to
        you
      </li>
      <li><span class="bullet" style="background: {{config?.bulletColor}}"></span>Ground floor disassembly
        only
      </li>
      <li><span class="bullet" style="background: {{config?.bulletColor}}"></span>Items accepted based on
        condition
      </li>
      <li><span class="bullet" style="background: {{config?.bulletColor}}"></span>Scheduling based on
        availability
      </li>
      <li *ngIf="showRestricedItems"><span class="bullet" style="background: {{config?.bulletColor}}"></span>
        <a href="{{config.restricedItemsUrl}}" style="color: {{config.textColor}}"
           target="_blank">{{config.restricedItemsText}}</a>
      </li>
    </ul>
    <ul *ngIf="version === splitScreenVersions.V1 && config.text?.trim()?.length > 0">
      <li *ngFor="let el of textValues"><span class="bullet"
                                              style="background: {{config?.bulletColor}}"></span>{{el}}</li>
      <li *ngIf="showRestricedItems"><span class="bullet" style="background: {{config?.bulletColor}}"></span>
        <a href="{{config.restricedItemsUrl}}" style="color: {{config.textColor}}"
           target="_blank">{{config.restricedItemsText}}</a>
      </li>
    </ul>
    <ul *ngIf="version === splitScreenVersions.V2 && screening && pickUp">
      <li>
        <mat-icon [ngClass]="{gray: screening.floor !== 'any'}" [svgIcon]="screening.floor === 'any' ? 'ss-primary-floor' : 'ss-standard-floor'"></mat-icon>
        {{screening.floor === 'curbside' ? 'Curbside pickups only' : ''}}
        {{screening.floor === 'first' ? 'Pickups from 1st floor only, no staircases' : ''}}
        {{screening.floor === 'second' ? 'Pickups from 2nd floor and below' : ''}}
        {{screening.floor === 'any' ? 'Pickups from any floor' : ''}}
      </li>
      <li>
        <mat-icon [ngClass]="{gray: !screening.disassembly}" [svgIcon]="screening.disassembly ? 'ss-primary-disassembly' :  'ss-standard-disassembly'"></mat-icon>
        {{screening.disassembly ? 'Item disassembly and removal' : 'Self disassembly required'}}
      </li>
      <li>
        <mat-icon class="gray" svgIcon="ss-standard-items"></mat-icon>
        <div fxLayout="column"><label>Donatable items only </label><a *ngIf="screening.restrictedItems.length > 0">See restricted items list</a></div>
      </li>
      <li>
        <mat-icon [ngClass]="{gray: screening.leadTime !== '3 days or less'}" [svgIcon]="screening.leadTime === '3 days or less' ? 'ss-primary-time' : 'ss-standard-time'"></mat-icon>
        Donation pickups in {{screening.leadTime}}
      </li>
    </ul>
    <p *ngIf="pickUp && version === splitScreenVersions.V2 && textValue" class="standard-additional-text" [innerHTML]="textValue">
    </p>
    <ul *ngIf="!pickUp && version === splitScreenVersions.V2 && textValues.length > 0">
      <li *ngFor="let el of textValues"><span class="bullet"
        style="background: {{config?.bulletColor}}"></span>{{el}}</li>
    </ul>
    <p *ngIf="version === splitScreenVersions.V2 && showRestricedItems" class="donation-guidlines" style="color: {{config.textColor}}">
      Please see our <a href="{{config.restricedItemsUrl}}"
         target="_blank">Donation Guidelines</a> before visiting one of our donation centers.
    </p>
    <div class="btn-wrapper" fxLayout="row" fxFlex fxLayoutAlign="center end">
      <a mat-button class="btn-block" [href]="config.url" target="_blank" *ngIf="config.standardReady"
         style="background: {{config.btnBackgroundColor}}; color: {{config.btnTextColor}}">
            <span
              *ngIf="config.standardReady">{{config.button ? config.button : 'Request Approval'}}</span>
      </a>
      <button mat-button class="btn-block" *ngIf="!config.standardReady"
              style="background: {{config.btnBackgroundColor}}; color: {{config.btnTextColor}}">
        <span *ngIf="!config.standardReady">Coming Soon!</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #PreviewTemplate>
  <div class="space" fxLayoutAlign="center center" fxLayout="column">
    <div fxLayoutGap="30px" class="cards {{version}}" [fxLayout]="(isDesktop && windowSize !== windowSizes.XS) ? 'row' : 'column'">
      <ng-container [ngTemplateOutlet]="reverse ? StandardTemplate : PrimaryTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="reverse ? PrimaryTemplate : StandardTemplate"></ng-container>
    </div>
  </div>
  <div fxLayout="row" class="social" fxLayoutGap="20px" fxLayoutAlign="end end">
    <a
      href="https://www.facebook.com/resupplyapp/?eid=ARDseOOUc71V0lp5-0QTY42vLkLjbxneImEoXfK9y7BqflDTH3-sM7O9fvG9ED82tA4ZmakojbSaRboA">
      <mat-icon svgIcon="facebook"></mat-icon>
    </a>
    <a href="https://www.instagram.com/resupplyme/">
      <mat-icon svgIcon="instagram"></mat-icon>
    </a>
    <a href="https://www.linkedin.com/company/resupplyme/?viewAsMember=true">
      <mat-icon svgIcon="linkedin"></mat-icon>
    </a>
  </div>
</ng-template>
<form [formGroup]="form" fxLayout="column" fxLayoutGap="30px" *ngIf="config">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="30px">
    <div fxLayout="column" fxLayoutGap="30px" fxFlex="100" fxFlex.md="50" fxFlex.gt-md="30">
      <rspl-card>
        <h2 class="card-title landing" fxLayout="row">
          Landing Page
          <mat-slide-toggle #daySlideToggle labelPosition="before" [checked]="enabled"
                            (change)="onEnabledChange($event.checked)"></mat-slide-toggle>
        </h2>
        <div fxLayout="row" *ngIf="isCaptain">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Design</mat-label>
            <mat-select
              (valueChange)="onVersionChange($event)"
              [value]="version"
              [disabled]="!enabled"
            >
              <mat-option *ngFor="let d of versions" [value]="d.value">{{
                d.text
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-tab-group (selectedTabChange)="selectedTab($event.tab.textLabel)" class="split-screen-config">
          <mat-tab [disabled]="!enabled" label="Priority Pick-Up" *ngIf="isCaptain">
            <h3>Position</h3>
            <div fxLayout="row">
              <mat-button-toggle-group fxFlex="100" [disabled]="!enabled">
                <mat-button-toggle fxFlex="50" [disabled]="!enabled" [checked]="!reverse"
                                   (click)="enabled ? onReverseChange(false) : null">
                  First (Left)
                </mat-button-toggle>
                <mat-button-toggle fxFlex="50" [disabled]="!enabled" [checked]="reverse"
                                   (click)="enabled ? onReverseChange(true) : null">
                  Second (Right)
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <h3>Title</h3>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <input formControlName="titlePrimary" matInput (change)="titlePrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{titleBackgroundColorPrimary.value}}">circle</mat-icon>
                <input formControlName="titleBackgroundColorPrimary" matInput
                       [(colorPicker)]="config.btnBackgroundColorPrimary"
                       (colorPickerChange)="titleBackgroundColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="titleBackgroundColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{titleTextColorPrimary.value}}">circle</mat-icon>
                <input formControlName="titleTextColorPrimary" matInput
                       [(colorPicker)]="config.btnTextColorPrimary"
                       (colorPickerChange)="titleTextColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="titleTextColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <h3>Stripe</h3>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{stripeBackgroundColor.value}}">circle</mat-icon>
                <input formControlName="stripeBackgroundColor" matInput
                       [(colorPicker)]="config.stripeBackgroundColor"
                       (colorPickerChange)="stripeBackgroundColor.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="stripeBackgroundColorChanged()">
              </mat-form-field>
            </div>
            <h3>Logo</h3>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{logoBackgroundColorPrimary.value}}">circle</mat-icon>
                <input formControlName="logoBackgroundColorPrimary" matInput
                       [(colorPicker)]="config.logoBackgroundColorPrimary"
                       (colorPickerChange)="logoBackgroundColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="logoBackgroundColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <h3>Description</h3>
            <div fxLayout="row" style="margin-bottom: 15px">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <textarea formControlName="textPrimary" matInput rows="10" placeholder="Primary pickup facts"
                          (change)="textPrimaryChanged()"></textarea>
                <mat-hint>Press enter to create bullets</mat-hint>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{backgroundColorPrimary.value}}">circle</mat-icon>
                <input formControlName="backgroundColorPrimary" matInput
                       [(colorPicker)]="config.backgroundColorPrimary"
                       (colorPickerChange)="backgroundColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="backgroundColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{textColorPrimary.value}}">circle</mat-icon>
                <input formControlName="textColorPrimary" matInput [(colorPicker)]="config.textColorPrimary"
                       (colorPickerChange)="textColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="textColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="version === splitScreenVersions.V1">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Bullet Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{bulletColorPrimary.value}}">circle</mat-icon>
                <input formControlName="bulletColorPrimary" matInput [(colorPicker)]="config.bulletColorPrimary"
                       (colorPickerChange)="bulletColorPrimary.setValue($event)"
                       cpPosition="bottom-right"
                       (change)="bulletColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <h3>Button</h3>
            <div fxLayout="row">
              <mat-slide-toggle labelPosition="before" [checked]="primaryReady"
                                [disabled]="!enabled"
                                (change)="onPrimaryReadyChange($event.checked)">Ready For Use
              </mat-slide-toggle>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <input formControlName="buttonPrimary" matInput (change)="btnTextPrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{btnBackgroundColorPrimary.value}}">circle</mat-icon>
                <input formControlName="btnBackgroundColorPrimary" matInput
                       [(colorPicker)]="config.btnBackgroundColorPrimary"
                       (colorPickerChange)="btnBackgroundColorPrimary.setValue($event)"
                       cpPosition="top-right"
                       (change)="btnBackgroundColorPrimaryChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{btnTextColorPrimary.value}}">circle</mat-icon>
                <input formControlName="btnTextColorPrimary" matInput [(colorPicker)]="config.btnTextColorPrimary"
                       (colorPickerChange)="btnTextColorPrimary.setValue($event)"
                       cpPosition="top-right"
                       (change)="btnTextColorPrimaryChanged()">
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab [disabled]="!enabled" label="Standard Pick-Up">
            <div fxLayout="row">
              <mat-slide-toggle labelPosition="before" [checked]="standardEnabled"
                                [disabled]="!enabled"
                                (change)="onStandardEnabledChange($event.checked)">Enabled
              </mat-slide-toggle>
            </div>
            <h3>Title</h3>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <input formControlName="title" matInput (change)="titleChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{titleBackgroundColor.value}}">circle</mat-icon>
                <input formControlName="titleBackgroundColor" matInput [(colorPicker)]="config.btnBackgroundColor"
                       (colorPickerChange)="titleBackgroundColor.setValue($event)"
                       (change)="titleBackgroundColorChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{titleTextColor.value}}">circle</mat-icon>
                <input formControlName="titleTextColor" matInput [(colorPicker)]="config.btnTextColor"
                       (colorPickerChange)="titleTextColor.setValue($event)"
                       (change)="titleTextColorChanged()">
              </mat-form-field>
            </div>
            <h3>Logo</h3>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{logoBackgroundColor.value}}">circle</mat-icon>
                <input formControlName="logoBackgroundColor" matInput
                       [(colorPicker)]="config.logoBackgroundColor"
                       (colorPickerChange)="logoBackgroundColor.setValue($event)"
                       (change)="logoBackgroundColorChanged()">
              </mat-form-field>
            </div>
            <h3>Description</h3>
            <div fxLayout="row" style="margin-bottom: 15px">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <textarea formControlName="text" matInput rows="10" placeholder="Free pickup facts"
                          (change)="textChanged()"></textarea>
                <mat-hint>Press enter to create bullets</mat-hint>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{backgroundColor.value}}">circle</mat-icon>
                <input formControlName="backgroundColor" matInput [(colorPicker)]="config.backgroundColor"
                       (colorPickerChange)="backgroundColor.setValue($event)"
                       (change)="backgroundColorChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{textColor.value}}">circle</mat-icon>
                <input formControlName="textColor" matInput [(colorPicker)]="config.textColor"
                       (colorPickerChange)="textColor.setValue($event)"
                       (change)="textColorChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Bullet Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{bulletColor.value}}">circle</mat-icon>
                <input formControlName="bulletColor" matInput [(colorPicker)]="config.bulletColor"
                       (colorPickerChange)="bulletColor.setValue($event)"
                       (change)="bulletColorChanged()">
              </mat-form-field>
            </div>
            <h3>Button</h3>
            <div fxLayout="row">
              <mat-slide-toggle labelPosition="before" [checked]="standardReady"
                                [disabled]="!enabled || !standardEnabled"
                                (change)="onStandardReadyChange($event.checked)">Ready For Use
              </mat-slide-toggle>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <input formControlName="button" matInput (change)="btnTextChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>URL</mat-label>
                <input formControlName="url" matInput placeholder="http://">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Background Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{btnBackgroundColor.value}}">circle</mat-icon>
                <input formControlName="btnBackgroundColor" matInput [(colorPicker)]="config.btnBackgroundColor"
                       (colorPickerChange)="btnBackgroundColor.setValue($event)"
                       (change)="btnBackgroundColorChanged()">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text Color</mat-label>
                <mat-icon matPrefix class="color-picked" style="color: {{btnTextColor.value}}">circle</mat-icon>
                <input formControlName="btnTextColor" matInput [(colorPicker)]="config.btnTextColor"
                       (colorPickerChange)="btnTextColor.setValue($event)"
                       (change)="btnTextColorChanged()">
              </mat-form-field>
            </div>

            <h3>{{ version === splitScreenVersions.V1 ? 'Restricted Items' : 'Donation Guidelines'}} URL</h3>
            <div fxLayout="row">
              <mat-slide-toggle labelPosition="before" [checked]="showRestricedItems"
                                [disabled]="!enabled || !standardEnabled"
                                (change)="onShowRestricedItemsChange($event.checked)">Show
              </mat-slide-toggle>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>URL</mat-label>
                <input formControlName="restricedItemsUrl" matInput placeholder="http://">
              </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="version === splitScreenVersions.V1">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Text</mat-label>
                <input formControlName="restricedItemsText" matInput (change)="restrictedTextChanged()">
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab [disabled]="!enabled" label="Logo">
            <div fxFlex="100" fxLayout="column" *ngIf="showLogo">
              <h3>Logo Configuration</h3>
              <mat-tab-group #logoTabs (selectedTabChange)="logoTabChanged($event)">
                <mat-tab label="Logo Image" [disabled]="!enabled">
                  <rspl-photo-upload
                    [disabled]="!enabled"
                    [currentImageUrl]="config?.logo || charity.logo || '/assets/images/no-img-placeholder.png'"
                    (uploadedImageUrl)='getUploadedFileUrl($event)'>
                  </rspl-photo-upload>
                </mat-tab>
                <mat-tab label="Desktop Positioning" *ngIf="!smallWindowSizes.includes(windowSize)" [disabled]="!enabled">
                  <div fxLayout="column" fxLayoutGap="10px">
                    <rspl-card cardTitle="Priority">
                      <div fxLayout="row">
                        <div fxFlex="100" fxLayout="column" fxFlexOffset="10px">
                          <div fxFlex fxLayout="column">
                            <mat-label>Size</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Size" min="-40" max="40" step="1"
                                        tickInterval="40"
                                        formControlName="logoSize"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Horizontal Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Left Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoOffsetLeft"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Vertical Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Top Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoOffsetTop"></mat-slider>
                          </div>
                        </div>
                      </div>
                    </rspl-card>
                    <rspl-card cardTitle="Standard">
                      <div fxLayout="row">
                        <div fxFlex="100" fxLayout="column" fxFlexOffset="10px">
                          <div fxFlex fxLayout="column">
                            <mat-label>Size</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Size" min="-40" max="40" step="1"
                                        tickInterval="40"
                                        formControlName="logoStandardSize"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Horizontal Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Left Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoStandardOffsetLeft"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Vertical Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Top Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoStandardOffsetTop"></mat-slider>
                          </div>
                        </div>
                        </div>
                    </rspl-card>
                  </div>
                </mat-tab>
                <mat-tab label="Mobile Positioning" [disabled]="!enabled">
                  <div fxLayout="column" fxLayoutGap="10px">
                    <rspl-card cardTitle="Primary">
                      <div fxLayout="row">
                        <div fxFlex="100" fxLayout="column" fxFlexOffset="10px">
                          <div fxFlex fxLayout="column">
                            <mat-label>Size</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Size" min="-40" max="40" step="1"
                                        tickInterval="40"
                                        formControlName="logoSizeMobile"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Horizontal Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Left Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoOffsetLeftMobile"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Vertical Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Top Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoOffsetTopMobile"></mat-slider>
                          </div>
                        </div>
                      </div>
                    </rspl-card>
                    <rspl-card cardTitle="Standard">
                      <div fxLayout="row">
                        <div fxFlex="100" fxLayout="column" fxFlexOffset="10px">
                          <div fxFlex fxLayout="column">
                            <mat-label>Size</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Size" min="-40" max="40" step="1"
                                        tickInterval="40"
                                        formControlName="logoStandardSizeMobile"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Horizontal Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Left Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoStandardOffsetLeftMobile"></mat-slider>
                          </div>
                          <div fxFlex fxLayout="column">
                            <mat-label>Vertical Position</mat-label>
                            <mat-slider [disabled]="!enabled" aria-label="Top Offset" min="-30" max="30" step="1"
                                        tickInterval="30"
                                        formControlName="logoStandardOffsetTopMobile"></mat-slider>
                          </div>
                        </div>
                      </div>
                    </rspl-card>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-tab>
        </mat-tab-group>
      </rspl-card>

    </div>

    <rspl-card *ngIf="windowSize === windowSizes.XS" cardTitle="Preview" class="landing-preview">
      <ng-container *ngTemplateOutlet="PreviewTemplate"></ng-container>
    </rspl-card>
    <div *ngIf="windowSize !== windowSizes.XS" fxLayout="column" fxLayoutGap="30px" fxFlex="100" fxFlex.md="50"
         fxFlex.gt-md="70">
      <div fxLayout="row" fxLayoutGap="1" fxLayoutAlign="center" class="preview-buttons">
        <mat-button-toggle-group (valueChange)="setPreview($event)" [disabled]="!enabled">
          <mat-button-toggle value="desktop" [checked]="isDesktop">Desktop</mat-button-toggle>
          <mat-button-toggle value="mobile" [checked]="!isDesktop">Mobile</mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-raised-button [disabled]="!enabled" (click)="toggleFullScreen()" *ngIf="isDesktop && !fullscreen"
                class="fullscreen-btn"
                type="button">
          <mat-icon *ngIf="!fullscreen">fullscreen</mat-icon>
          <span *ngIf="!fullscreen">Full Screen</span>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <rspl-card [class]="preview + ' landing-preview'" *ngIf="isDesktop" [ngClass]="{'fullscreen' : fullscreen}"
                  [ngClass]="{'disabled': !enabled}">

          <button mat-raised-button (click)="toggleFullScreen()" *ngIf="isDesktop && fullscreen" class="fullscreen-btn"
                  type="button">
            <mat-icon *ngIf="fullscreen">fullscreen_exit</mat-icon>
            <span *ngIf="fullscreen">Exit Full Screen</span>
          </button>
          <ng-container *ngTemplateOutlet="PreviewTemplate"></ng-container>
        </rspl-card>
        <div [class]="preview" *ngIf="!isDesktop" [ngClass]="{'disabled': !enabled}" class="mobile">
          <div>
            <ng-container *ngTemplateOutlet="PreviewTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="save-button {{app}}">
    <button class="primary-btn" mat-raised-button
            rsplDisableDoubleClick (oneClick)="submitForm()">Save Configuration
    </button>
  </div>
</form>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StripeService } from '@rspl-api';
import { StripePayments } from '@domains';
import * as fromActions from './payment.actions';
import { Deserialize } from 'cerialize';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PaymentsEffects {

  getPayments = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPayments),
      switchMap((action) =>
        this.service.stripePayments(action.id, action.limit, action.last)
          .pipe(
            map((results) => {
                return fromActions.getPaymentsSuccess({
                  results: new StripePayments(Deserialize(results, StripePayments))
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: StripeService
  ) {
  }
}

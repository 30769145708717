import { Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'rspl-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss']
})
export class WeekPickerComponent {
  public weekStart: any;
  public weekEnd: any;
  public week: number;
  public year: number;
  @Output() weekChanged = new EventEmitter();

  constructor() {
    const currentDate = moment();
    this.weekStart = currentDate.clone().startOf('week');
    this.weekEnd = currentDate.clone().endOf('week');
    this.week = this.weekStart.week();
    this.year = this.weekStart.year();
  }

  moveWeek(step: number): void {
    this.weekStart = moment(this.weekStart).add(step * 7, 'days');
    this.weekEnd = moment(this.weekEnd).add(step * 7, 'days');
    this.week = this.weekStart.week();
    this.year = this.weekStart.year();
    this.weekChanged.emit({
      weekStart: this.weekStart,
      weekEnd: this.weekEnd
    });
  }

}

<h2 mat-dialog-title>Cancel donation</h2>
<div mat-dialog-content class="cancel-donation-dialog">
  <div fxLayout="row" fxLayoutGap="20px">
    <p>Are you sure you want to cancel donation {{data?.donationCode}}?</p>
  </div>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" *ngIf="isCaptain">
      <mat-label>Cancel was initiated by</mat-label>
      <mat-radio-group [(ngModel)]="asDonor" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" (change)="asDonorChange()">
        <mat-radio-button [value]="false">3PL</mat-radio-button>
        <mat-radio-button [value]="true">Donor</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Reason</mat-label>
      <mat-hint>Please choose a reason why you are canceling</mat-hint>
      <mat-select matInput
                  [formControl]="reason"
                  panelClass="pick-us-state"
                  disableOptionCentering="true"
                  (selectionChange)="setSelectedReason()"
      >

        <mat-option *ngFor="let option of reasons" [value]="option">
          {{option.reason}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex *ngIf="selectedReason?.children">
      <mat-label>Sub-Reason</mat-label>
      <mat-select matInput
                  [formControl]="subReason"
                  panelClass="pick-us-state"
                  disableOptionCentering="true"
      >
        <mat-option *ngFor="let option of selectedReason.children" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea [formControl]="additionalText" matInput></textarea>
    </mat-form-field>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" *ngIf="data?.pricing?.cancellationFee > 0 && asDonor">
      <mat-label>Charge Donor Cancellation Fee</mat-label>
      <mat-radio-group [(ngModel)]="chargeCancellation" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" *ngIf="isCaptain && (data?.pricing?.bookingFee > 0 && !asDonor)">
      <mat-label>Refund Booking Fee</mat-label>
      <mat-radio-group [(ngModel)]="bookingFeeRefund" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button (click)="onChoice(false)">No</button>
  <button mat-raised-button color="warn" (click)="onChoice(true)">Cancel donation
  </button>
</div>

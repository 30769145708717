import { Action, createReducer, on } from '@ngrx/store';
import { AvailabilityStats, CharityStore, Schedule} from '@domains';
import * as fromActions from './charity-store.actions';

export interface StoresState {
  stores: Array<CharityStore>;
  stateStores: Array<CharityStore>;
  closestStores: Array<CharityStore>;
  charityStores: Array<CharityStore>;
  storesTotalCount: number;
  store: CharityStore;
  availabilityStats: Array<AvailabilityStats>;

  deletedStoreId: number;
  schedules: Array<Schedule>;
  schedulesTotalCount: number;
  schedule: Schedule;
  deletedScheduleId: number;
}

const initialState: StoresState = {
  stores: new Array<CharityStore>(),
  storesTotalCount: 0,
  store: new CharityStore(),
  stateStores: new Array<CharityStore>(),
  closestStores: new Array<CharityStore>(),
  charityStores: new Array<CharityStore>(),
  availabilityStats: new Array<AvailabilityStats>(),
  deletedStoreId: 0,

  schedules: new Array<Schedule>(),
  schedulesTotalCount: 0,
  schedule: new Schedule(),
  deletedScheduleId: 0,
};

const reducer = createReducer(
  initialState,

  on(fromActions.createStoreSuccess, (state, action: any) => ({...state, store: action.store})),
  on(fromActions.updateStoreSuccess, (state, action: any) => ({...state, store: action.store})),

  on(fromActions.getStores, (state) => {
    return {...state, stores: []};
  }),
  on(fromActions.getStoresSuccess, (state, action: any) => ({...state, stores: action.stores, storesTotalCount: action.resultsCount,})),

  on(fromActions.getCharityStores, (state, {keep}) => ({...state, charityStores: keep ? state.charityStores : []})),
  on(fromActions.getCharityStoresSuccess, (state, action: any) => ({...state, charityStores: action.stores})),

  on(fromActions.getStateStores, state => ({...state, stateStores: []})),
  on(fromActions.getStateStoresSuccess, (state, action: any) => ({...state, stateStores: action.stores})),

  on(fromActions.getClosestStores, state => ({...state, closestStores: []})),
  on(fromActions.getClosestStoresSuccess, (state, action: any) => ({...state, closestStores: action.stores})),

  on(fromActions.getStore, (state, {id, keep}) => ({...state, store: keep ? state.store : null})),
  on(fromActions.getStoreSuccess, (state, action: any) => ({...state, store: action.store})),

  on(fromActions.getStoreAvailabilityStats, (state, action: any) => ({...state, availabilityStats: []})),
  on(fromActions.getStoreAvailabilityStatsSuccess, (state, action: any) => ({...state, availabilityStats: action.stats})),

  on(fromActions.deleteStoreSuccess, (state, action: any) => ({...state, deletedstoreId: action.storeId})),

  on(fromActions.createStoreScheduleSuccess, (state, action: any) => ({...state, schedule: action.schedule})),
  on(fromActions.updateStoreScheduleSuccess, (state, action: any) => ({...state, schedule: action.schedule})),

  on(fromActions.getStoreSchedules, state => ({...state, schedules: []})),

  on(fromActions.getStoreSchedulesSuccess, (state, action: any) => ({...state, schedules: action.schedules})),

  on(fromActions.getStoreScheduleSuccess, (state, action: any) => ({...state, schedule: action.schedule})),

  on(fromActions.deleteStoreScheduleSuccess, (state, action: any) => ({...state, deletedScheduleId: action.scheduleId})),
);

export function storesReducer(state: StoresState | undefined, action: Action) {
  return reducer(state, action);
}

import { Charity, FindParamsDefault, Screening } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum CharityActionType {
  GetCharities = '[Charity] Get Charities',
  GetCharitiesSuccess = '[Charity] Get Charities Success',

  GetCharity = '[Charity] Get Charity',
  GetCharitySuccess = '[Charity] Get Charity Success',

  CreateCharity = '[Charity] Create Charity',
  CreateCharitySuccess = '[Charity] Create Charity Success',

  UpdateCharity = '[Charity] Update Charity',
  UpdateCharitySuccess = '[Charity] Update Charity Success',
  UpdateCharityFail = '[Charity] Update Charity Fail',

  DeleteCharity = '[Charity] Delete Charity',
  DeleteCharitySuccess = '[Charity] Delete Charity Success',

  RestoreCharity = '[Charity] Restore Charity',
  RestoreCharitySuccess = '[Charity] Restore Charity Success',

  GetCharityScreening = '[Charity] Get Charity Screening',
  GetCharityScreeningSuccess = '[Charity] Get Charity Screening Success',
  CreateCharityScreening = '[Charity] Create Charity Screening',
  CreateCharityScreeningSuccess = '[Charity] Create Charity Screening Success',
  UpdateCharityScreening = '[Charity] Update Charity Screening',
  UpdateCharityScreeningSuccess = '[Charity] Update Charity Screening Success',
}

export const getCharities = createAction(
  CharityActionType.GetCharities,
  props<{ findParams: FindParamsDefault; keep?: boolean; reload?: boolean }>()
);
export const getCharitiesSuccess = createAction(
  CharityActionType.GetCharitiesSuccess,
  props<{
    resultsCount: number;
    results: Array<Charity>;
  }>()
);

export const getCharity = createAction(
  CharityActionType.GetCharity,
  props<{ id: string; keep?: boolean }>()
);
export const getCharitySuccess = createAction(
  CharityActionType.GetCharitySuccess,
  props<{ charity: Charity }>()
);

export const createCharity = createAction(
  CharityActionType.CreateCharity,
  props<{ charity: Charity }>()
);
export const createCharitySuccess = createAction(
  CharityActionType.CreateCharitySuccess,
  props<{ charity: Charity }>()
);

export const updateCharity = createAction(
  CharityActionType.UpdateCharity,
  props<{ charity: Charity }>()
);
export const updateCharitySuccess = createAction(
  CharityActionType.UpdateCharitySuccess,
  props<{ charity: Charity }>()
);
export const updateCharityFail = createAction(
  CharityActionType.UpdateCharityFail
);

export const deleteCharity = createAction(
  CharityActionType.DeleteCharity,
  props<{ charityId: string }>()
);
export const deleteCharitySuccess = createAction(
  CharityActionType.DeleteCharitySuccess,
  props<{ charityId: string }>()
);

export const restoreCharity = createAction(
  CharityActionType.RestoreCharity,
  props<{ charityId: string }>()
);
export const restoreCharitySuccess = createAction(
  CharityActionType.RestoreCharitySuccess,
  props<{ charityId: string }>()
);

export const getCharityScreening = createAction(
  CharityActionType.GetCharityScreening,
  props<{ charityId: string }>()
);
export const getCharityScreeningSuccess = createAction(
  CharityActionType.GetCharityScreeningSuccess,
  props<{ screening: Screening }>()
);
export const createCharityScreening = createAction(
  CharityActionType.CreateCharityScreening,
  props<{ screening: Screening }>()
);
export const createCharityScreeningSuccess = createAction(
  CharityActionType.CreateCharityScreeningSuccess
);
export const updateCharityScreening = createAction(
  CharityActionType.UpdateCharityScreening,
  props<{ screening: Screening }>()
);
export const updateCharityScreeningSuccess = createAction(
  CharityActionType.UpdateCharityScreeningSuccess
);

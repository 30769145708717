import { FindParamsDefault, Item } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum ItemActionType {
  ClearItemsFindParams = '[Captain] Clear Items Find Params',
  ClearItems = '[Captain] Clear Items',
  GetItems = '[Item] Get Items',
  GetItemsSuccess = '[Item] Get Items Success',

  GetItem = '[Item] Get Item',
  GetItemSuccess = '[Item] Get Item Success',

  CreateItem = '[Item] Create Item',
  CreateItemSuccess = '[Item] Create Item Success',

  UpdateItem = '[Item] Update Item',
  UpdateItemSuccess = '[Item] Update Item Success',
  UpdateItemFail = '[Item] Update Item Fail',

  DeleteItem = '[Item] Delete Item',
  DeleteItemSuccess = '[Item] Delete Item Success',

  RestoreItem = '[Item] Restore Item',
  RestoreItemSuccess = '[Item] Restore Item Success',
}

export const clearItemsFindParams = createAction(ItemActionType.ClearItemsFindParams);
export const clearItems = createAction(ItemActionType.ClearItems);
export const getItems = createAction(ItemActionType.GetItems, props<{ findParams: FindParamsDefault, keep?: boolean }>());
export const getItemsSuccess = createAction(ItemActionType.GetItemsSuccess, props<{
  resultsCount: number,
  results: Array<Item>
}>());

export const getItem = createAction(ItemActionType.GetItem, props<{ id: string, expand?: string[] }>());
export const getItemSuccess = createAction(ItemActionType.GetItemSuccess, props<{ item: Item }>());

export const createItem = createAction(ItemActionType.CreateItem, props<{ item: Item }>());
export const createItemSuccess = createAction(ItemActionType.CreateItemSuccess, props<{ item: Item }>());

export const updateItem = createAction(ItemActionType.UpdateItem, props<{ item: Item }>());
export const updateItemSuccess = createAction(ItemActionType.UpdateItemSuccess, props<{ item: Item }>());
export const updateItemFail = createAction(ItemActionType.UpdateItemFail);

export const deleteItem = createAction(ItemActionType.DeleteItem, props<{ itemId: string }>());
export const deleteItemSuccess = createAction(ItemActionType.DeleteItemSuccess, props<{ itemId: string }>());

export const restoreItem = createAction(ItemActionType.RestoreItem, props<{ itemId: string }>());
export const restoreItemSuccess = createAction(ItemActionType.RestoreItemSuccess, props<{ itemId: string }>());

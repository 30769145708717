import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  cnt: number; // number of requests
  max: number; // max number of requests
  blacklist = [''];

  constructor(
    private loaderService: LoaderService,
    @Inject(ENVIRONMENT) private config: Environment,
    private zone: NgZone
  ) {
    this.cnt = 0;
    this.max = 0;
    window.addEventListener('reduce_loader_req', () => {
      this.zone.run(() => {
        this.hideLoader();
      });
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Upload has to be be implemented individually
    // This condition presumes that URL of every api endpoint used for uploading will contain the word "upload"
    if (
      req.url.toUpperCase().indexOf('UPLOAD') === -1 &&
      !this.blacklist?.includes(req.url) &&
      req.url.includes(this.config.urls.baseUrl)
    ) {
      this.showLoader();
      return next.handle(req).pipe(
        finalize(() => {
          this.hideLoader();
        })
      );
    }
    return next.handle(req);
  }

  private showLoader(): void {
    this.loaderService.show(++this.cnt, ++this.max);
  }

  private hideLoader(): void {
    this.cnt--;
    if (this.cnt <= 0) {
      if (this.cnt === 0) {
        // show full loader before hiding it
        this.loaderService.show(0, this.max);
      }
      this.max = 0;
      this.loaderService.hide();
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoresService } from '@rspl-api';
import * as fromActions from './charity-store.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable()
export class StoreEffects {

  createStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createStore),
      mergeMap((payload) =>
        this.service.create(payload.store)
          .pipe(
            map((result) => {
                return fromActions.createStoreSuccess({
                  store: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateStore),
      mergeMap((payload) => {
          return this.service.update(payload.store.id, payload.store)
            .pipe(
              map((result) => {
                  return fromActions.updateStoreSuccess({
                    store: result
                  });
                }
              ),
              catchError(() => EMPTY)
            );
        }
      )
    );
  });

  getStores = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStores),
      switchMap((params) =>
        this.service.filter(params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getStoresSuccess({
                  stores: results.results,
                  resultsCount: results.totalResults,
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getCharityStores = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCharityStores),
      switchMap((params) =>
        this.service.filter({
          charity_id: params.charityId,
          expand: "charity"
        })
          .pipe(
            map((results) => {
                return fromActions.getCharityStoresSuccess({
                  stores: results.results
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getStateStores = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStateStores),
      switchMap((params) =>
        this.service.filter(params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getStateStoresSuccess({
                  stores: results.results
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getClosestStores = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getClosestStores),
      switchMap((params) =>
        this.service.getClosestStores(params.id)
          .pipe(
            map((results) => {
                return fromActions.getClosestStoresSuccess({
                  stores: results
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStore),
      switchMap((params) =>
        this.service.find(params.id)
          .pipe(
            map((result) => {
                return fromActions.getStoreSuccess({
                  store: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

    getStoreAvailabilityStats = createEffect(() => {
      return this.actions$.pipe(
        ofType(fromActions.getStoreAvailabilityStats),
        switchMap((params) =>
          this.service.getAvailableStores(params.findParams)
            .pipe(
              map((results) => {
                  return fromActions.getStoreAvailabilityStatsSuccess({
                    stats: results
                  });
                }
              ),
              catchError(() => EMPTY)
            )
        )
      );
    });

  deleteStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteStore),
      switchMap((params) =>
        this.service.delete(params.storeId)
          .pipe(
            map((result) => {
                return fromActions.deleteStoreSuccess({
                  storeId: params.storeId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  restoreStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreStore),
      switchMap((params) =>
        this.service.restore(params.storeId)
          .pipe(
            map((result) => {
                return fromActions.restoreStoreSuccess({
                  storeId: params.storeId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  createStoreSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createStoreSchedule),
      mergeMap((payload) =>
        this.service.createSchedule(payload.parentId, payload.schedule)
          .pipe(
            map((result) => {
                return fromActions.createStoreScheduleSuccess({
                  schedule: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateStoreSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateStoreSchedule),
      mergeMap((payload) =>
        this.service.updateSchedule(payload.parentId, payload.schedule)
          .pipe(
            map((result) => {
                return fromActions.updateStoreScheduleSuccess({
                  schedule: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getStoreSchedules = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStoreSchedules),
      switchMap((params) =>
        this.service.getSchedules(params.parentId, params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getStoreSchedulesSuccess({
                  schedules: results
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getStoreSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getStoreSchedule),
      switchMap((params) =>
        this.service.getSchedule(params.storeId, params.scheduleId)
          .pipe(
            map((result) => {
                return fromActions.getStoreScheduleSuccess({
                  schedule: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  deleteStoreSchedule = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteStoreSchedule),
      switchMap((params) =>
        this.service.deleteSchedule(params.storeId, params.scheduleId)
          .pipe(
            map((result) => {
                return fromActions.deleteStoreScheduleSuccess({
                  scheduleId: params.scheduleId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: StoresService
  ) {
  }
}

import { Injectable } from '@angular/core';
import { Item } from '@domains';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ItemService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './item.actions';

@Injectable()
export class ItemEffects {

  getItems = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getItems),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getItemsSuccess({
              resultsCount: results.totalResults,
              results: results.results.map((p) => new Item(p)),
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getItem),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand).pipe(
          map((result) => {
            return fromActions.getItemSuccess({
              item: new Item(result),
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  createItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createItem),
      switchMap((payload) => {
        return this.service.create(payload.item).pipe(
          map((result) => {
            return fromActions.createItemSuccess({
              item: result,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  updateItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateItem),
      switchMap((payload) =>
        this.service.update(payload.item.id, payload.item).pipe(
          map((result) => {
            return fromActions.updateItemSuccess({
              item: result,
            });
          }),
          catchError(() => [fromActions.updateItemFail()])
        )
      )
    );
  });

  deleteItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteItem),
      switchMap((params) =>
        this.service.delete(params.itemId).pipe(
          map((result) => {
            return fromActions.deleteItemSuccess({
              itemId: params.itemId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  restoreItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreItem),
      switchMap((params) =>
        this.service.restore(params.itemId).pipe(
          map((result) => {
            return fromActions.restoreItemSuccess({
              itemId: params.itemId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: ItemService
  ) {}
}

import { Params } from '@angular/router';
import { PageAction } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum PageActionType {
  SetPageHeader = '[Page Header] Set Page Header',
}

export const setPageHeader = createAction(PageActionType.SetPageHeader, props<{
  hasBackButton?: boolean,
  pageTitle?: string,
  subtitle?: string,
  actions?: PageAction[],
  back?: string,
  queryParams?: Params
}>());

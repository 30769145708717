import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MapService } from 'ngx-mapbox-gl';
import { RsplVersionConfig, RSPL_VERSION_CONFIG } from './rspl-version-config';
import { VersionWarningComponent } from './version-check';
import { VersionCheckInterceptor } from './version-check/version-check.interceptor';

@NgModule({
  declarations: [VersionWarningComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
  ],
  exports: [],
  providers: [],
})
export class RsplVersionModule {
  public static forRoot(
    config: RsplVersionConfig
  ): ModuleWithProviders<RsplVersionModule> {
    return {
      ngModule: RsplVersionModule,
      providers: [
        RsplVersionModule,
        MapService,
        { provide: RSPL_VERSION_CONFIG, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: VersionCheckInterceptor,
          multi: true,
        },
      ],
    };
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Market } from '@domains';
import { MarketsService } from '@rspl-api';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-market-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
})
export class MarketLookupComponent extends LookupComponent<Market> {
  constructor(
    protected override apiService: MarketsService,
    protected override dialog: MatDialog
  ) {
    super(apiService, dialog);
  }
}

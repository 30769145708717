import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Organization } from '@domains';
import { BaseApiService } from '../base-api.service';
import { ENVIRONMENT, Environment } from '@domains';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseApiService<Organization> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(config, http, 'organizations', Organization, 'Organization');
  }
}

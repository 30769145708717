import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Day } from '@domains';
import { WeekPickerComponent } from '@rspl-ui';
import { ScheduleConfig } from '../../components/schedule/schedule-config';
import { ScheduleFormComponent } from '../../components/schedule/schedule-form/schedule-form.component';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  @Input() config: ScheduleConfig;
  @Input() showMap = true;
  @Input() day: Day;
  @ViewChild(WeekPickerComponent) weekPicker: WeekPickerComponent;
  @ViewChild(ScheduleFormComponent) schedule: ScheduleFormComponent;
  isDefault = true;
  @Output() scheduleSaved = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectedIndexChange(index: number): void {
    if (index === 0) {
      this.isDefault = true;
      this.schedule.fetchData();
    } else {
      this.isDefault = false;
      this.schedule.fetchData(moment().week(), moment().year());
    }
  }
}


export enum InputActivity {
  ADDRESS_ENTER = 'addressEnter',
  ADDRESS_MANUAL = 'addressManual',
  ADDRESS_ENTER_ERROR = 'addressEnterError',
  FIRST_AVAILABLE_DATE = 'firstAvailableDate',
  DATE_SELECTED = 'dateSelected',
  IN_ZONE = 'inZone',
  OUT_OF_ZONE = 'outOfZone',
  OUT_OF_ZONE_VEHICLE = 'outOfZoneVehicle',
  OUT_OF_ZONE_STORE = 'outOfZoneStore',
  SCREENING_ZIP = 'screeningZip',
  SCREENING_FLOOR = 'screeningFloor',
  SCREENING_DISASSEMBLY = 'screeningDisassembly',
  SCREENING_RESTRICTED_ITEMS = 'screeningRestrictedItems',
  SCREENING_DAMAGED = 'screeningDamaged',
}

export enum ButtonActivity {
  ADDRESS_ATTEMPT = 'addressAttempt',
  GOODWILL_SHOP_LANDING_PAGE_SUBMIT = 'goodwillShopLandingPageSubmit',
  PRIORITY_OPTION_CLICKED = 'priorityOption',
  FREE_OPTION_CLICKED = 'freeOption',
  DONATION_SPEC_XL = 'donationSpecXLarge',
  DONATION_SPEC_LG = 'donationSpecLarge',
  DONATION_SPEC_MD = 'donationSpecMedium',
  DONATION_SPEC_SM = 'donationSpecSmall',
  DONATION_SPEC_STAIR_CASES = 'donationSpecStairCases',
  DONATION_SPEC_ELEVATOR = 'donationSpecElevator',
  DONATION_SPEC_DISASSEMBLY = 'donationSpecDisassembly',
  DONATION_SPEC_SUBMIT = 'donationSpecSubmit',
  DONATION_SPEC_SUBMIT_ERROR = 'donationSpecSubmitError',
  AVAILABILITY_SUBMIT = 'availabilitySubmit',
  AVAILABILITY_SUBMIT_ERROR = 'availabilitySubmitError',
  DONOR_INFO_SUBMIT = 'donorInfoSubmit',
  DONOR_INFO_SUBMIT_ERROR = 'donorInfoSubmitError',
  PAYMENT_CHECKBOX = 'paymentCheckbox',
  PAYMENT_SETUP_SUBMIT = 'paymentSetupSubmit',
  PAYMENT_SETUP_SUCCESS_APPLE = 'paymentSetupSuccessApple',
  PAYMENT_SETUP_SUCCESS_GOOGLE = 'paymentSetupSuccessGoogle',
  PAYMENT_SETUP_SUBMIT_ERROR = 'paymentSetupSubmitError',
  SCREENING_SUBMIT = 'screeningSubmit',
  SCREENING_SUBMIT_ERROR = 'screeningSubmitError',
  SCREENING_STANDARD = 'screeningStandard',
  SCREENING_PRIORITY = 'screeningPriority',
  SCREENING_DROP_OFF = 'screeningDropOff',
  SCREENING_YOUTUBE = 'screeningYoutube',

  // ************************ DONATION BUTTON ACTIVITIES ***********************
  ADD_TO_CALENDAR = 'addToCalendar',
  RESCHEDULE_DONATION = 'rescheduleDonation',
  RESCHEDULE_DONATION_SUBMIT = 'rescheduleDonationSubmit',
  RESCHEDULE_DONATION_SUBMIT_ERROR = 'rescheduleDonationSubmitError',
  CANCEL_DONATION = 'cancelDonation',
  CANCEL_DONATION_SUBMIT = 'cancelDonationSubmit',
  CANCEL_DONATION_SUBMIT_ERROR = 'cancelDonationSubmitError',
  EDIT_DONOR_INFO = 'editDonorInfo',
  EDIT_DONOR_INFO_SUBMIT = 'editDonorInfoSubmit',
  EDIT_DONOR_INFO_SUBMIT_ERROR = 'editDonorInfoSubmitError',
  EDIT_DONATION_SPEC = 'editDonationSpec',
  EDIT_DONATION_SPEC_SUBMIT = 'editDonationSpecSubmit',
  EDIT_DONATION_SPEC_SUBMIT_ERROR = 'editDonationSpecSubmitError',
  ADD_DONATION_PHOTOS = 'addDonationPhotos',
  ADD_DONATION_PHOTOS_SUBMIT = 'addDonationPhotosSubmit',
  ADD_DONATION_PHOTOS_SUBMIT_ERROR = 'addDonationPhotosSubmitError',
  TAX_RECEIPT = 'taxReceipt',
  RECEIPT = 'receipt',
  CONTINUE_TO_PAYMENT = 'continueToPayment',
  CONFIRM_PAYMENT = 'confirmPayment',
  CONFIRM_PAYMENT_ERROR = 'confirmPaymentError',
  SUBMIT_REVIEW = 'submitReview',
  SUBMIT_REVIEW_ERROR = 'submitReviewError',
  YELP = 'yelp',
  GOOGLE_MY_BUSINESS = 'googleMyBusiness',
  BETTER_BUSINESS_BUREAU = 'betterBusinessBureau',
  REQUEST_ETA = 'requestETA',
  UNKNOWN = 'unknown',
}

export enum PageActivity {
  // ************************ LEAD PAGE ACTIVITIES ***************************
  GOODWILL_SHOP_LANDING_PAGE = 'goodwillShopLanding',
  SPLIT_LANDING_PAGE = 'splitLanding',
  SCREENING_PAGE = 'screening',
  DONATION_SPEC_PAGE = 'donationSpec',
  AVAILABILITY_PAGE = 'availability',
  DONOR_INFO_PAGE = 'donorInfo',
  PAYMENT_SETUP_PAGE = 'paymentSetup',
  SPLASH_SCREEN_PAGE = 'splashScreen',

  // ************************ DONATION PAGE ACTIVITIES ***********************
  DONATION_MANAGEMENT_PAGE = 'donationManagement',
  EDIT_DONATION_PAGE = 'editDonation',
  RESCHEDULE_DONATION_PAGE = 'donationReschedule',
  CANCEL_DONATION_PAGE = 'donationCancellation',
  EDIT_DONOR_INFO_PAGE = 'donorInfoEdit',
  EDIT_DONATION_SPEC_PAGE = 'donationSpecEdit',
  DONATION_PHOTOS_PAGE = 'donationPhotos',
  PAYMENT_AUTHORIZATION_PAGE = 'paymentAuthorization',
  PAYMENT_SUCCESS_PAGE = 'paymentSuccess',
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrucksState } from './truck.reducers';

// tslint:disable: max-line-length
export const trucksFeatureStateSelector = createFeatureSelector<TrucksState>('truck');

export const selectTrucks = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.trucks);
export const selectTruck = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.truck);
export const selectStateTrucks = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.stateTrucks);
export const selectTrucksTotalCount = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.trucksTotalCount);
export const selectTruckSchedule = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.schedules);
export const selectAvailabilityStats = createSelector(trucksFeatureStateSelector, (state: TrucksState) => state.availabilityStats);


import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StripePayments } from '@domains';
import { Environment, ENVIRONMENT } from '@domains';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient
  ) {}

  stripeSetUp(id: string): void {
    this.http
      .post<{ url: string }>(
        this.config.urls.baseUrl + `/partners/${id}/connected/setup`,
        {
          refresh_url: `${location.origin}/payments`,
          return_url: `${location.origin}/payments`,
        }
      )
      .subscribe((data) => (window.location.href = data.url));
  }

  stripeLogIn(id: string): void {
    this.http
      .post<{ url: string }>(
        this.config.urls.baseUrl + `/partners/${id}/connected/login`,
        {}
      )
      .subscribe((data) => window.open(data.url, '_blank'));
  }

  stripePayments(
    id: string,
    limit?: number,
    last?: string
  ): Observable<StripePayments> {
    return this.http.get<StripePayments>(
      this.config.urls.baseUrl + `/partners/${id}/connected/payments`,
      {
        params: {
          ...(limit ? { limit: limit.toString() } : {}),
          ...(last ? { last } : {}),
        },
      }
    );
  }
}

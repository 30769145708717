<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline" fxFlex>
    <mat-label>Name</mat-label>
    <input [(ngModel)]="data.zone.name" matInput />
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button (click)="close()">No</button>
  <button
    class="primary-btn filled-btn"
    mat-raised-button
    rsplDisableDoubleClick
    (oneClick)="save()"
    [disabled]="!(data.zone.name.trim().length > 0)"
  >
    Yes
  </button>
</div>

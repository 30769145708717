import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrganizationsService } from '@rspl-api';
import * as fromActions from './organization.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable()
export class OrganizationEffects {

  createOrganization = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createOrganization),
      mergeMap((payload) =>
        this.service.create(payload.organization)
          .pipe(
            map((result) => {
                return fromActions.createOrganizationSuccess({
                  organization: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateOrganization = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateOrganization),
      mergeMap((payload) => {
          return this.service.update(payload.organization.id, payload.organization)
            .pipe(
              map((result) => {
                  return fromActions.updateOrganizationSuccess({
                    organization: result
                  });
                }
              ),
              catchError(() => [fromActions.updateOrganizationFail])
            );
        }
      )
    );
  });

  getOrganizations = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getOrganizations),
      switchMap((params) =>
        this.service.filter(params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getOrganizationsSuccess({
                  organizations: results.results,
                  resultsCount: results.totalResults
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getOrganization = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getOrganization),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand)
          .pipe(
            map((result) => {
                return fromActions.getOrganizationSuccess({
                  organization: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  deleteOrganization = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteOrganization),
      switchMap((params) =>
        this.service.delete(params.organizationId)
          .pipe(
            map((result) => {
                return fromActions.deleteOrganizationSuccess({
                  organizationId: params.organizationId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  restoreOrganization = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreOrganization),
      switchMap((params) =>
        this.service.restore(params.organizationId)
          .pipe(
            map((result) => {
                return fromActions.restoreOrganizationSuccess({
                  organizationId: params.organizationId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: OrganizationsService
  ) {
  }
}

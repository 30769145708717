import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageAction } from '@domains';
import { Store } from '@ngrx/store';
import { BackService, Responsive, ResponsiveService } from '@rspl-ui';
import { AppState } from '../../store/app-state.interface';
import { setPageHeader } from '../../store/page/page.actions';


@Component({
  selector: 'app-base-container',
  templateUrl: './base-container.component.html',
  styleUrls: ['./base-container.component.scss']
})

export class BaseContainerComponent extends Responsive {
  #page = false;
  @Input() set page(page: boolean) {
    this.#page = page;
    this.setBaseContainer();
  }

  get page(): boolean {
    return this.#page;
  }

  #hasBackButton = false;
  @Input() set hasBackButton(hasBackButton: boolean) {
    this.#hasBackButton = hasBackButton;
    this.setBaseContainer();
  }

  get hasBackButton(): boolean {
    return this.#hasBackButton;
  }

  #pageTitle?: string;
  @Input() set pageTitle(pageTitle: string | undefined) {
    this.#pageTitle = pageTitle;
    this.setBaseContainer();
  }

  get pageTitle(): string | undefined {
    return this.#pageTitle;
  }

  #subtitle?: string;
  @Input() set subtitle(subtitle: string | undefined) {
    this.#subtitle = subtitle;
    this.setBaseContainer();
  }

  get subtitle(): string | undefined {
    return this.#subtitle;
  }

  #actions: PageAction[] = [];
  @Input() set actions(actions: PageAction[]) {
    this.#actions = actions;
    this.setBaseContainer();
  }

  get actions(): PageAction[] {
    return this.#actions;
  }

  editMode = false;

  constructor(
    private router: Router,
    public store: Store<AppState>,
    private location: Location,
    private route: ActivatedRoute,
    private backService: BackService,
    public override responsiveService: ResponsiveService
  ) {
    super(responsiveService);
  }

  timeout?: any;

  private setBaseContainer(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (!this.page) {
      return;
    }
    this.timeout = setTimeout(() => {
      if (this.pageTitle) {
        this.store.dispatch(setPageHeader({
          pageTitle: this.pageTitle,
          subtitle: this.subtitle,
          hasBackButton: this.hasBackButton,
          actions: this.actions,
          back: this.backService.getBackPath(this.location, this.route),
          queryParams: this.route.snapshot.queryParams
        }));
      }
    });
  }

  back(): void {
    this.backService.back(this.location, this.route, this.router);
  }

  get visibleActionsCnt(): number {
    return this.actions?.filter(x => !x.hidden || !x.hidden())?.length;
  }
}

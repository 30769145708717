import { autoserialize, autoserializeAs } from 'cerialize';
import { ISpecification, Specification } from './specification';

export class DonationStateDTO {
  @autoserializeAs('state_target') stateTarget?: string;
  @autoserializeAs('state_action') stateAction: string;
  @autoserializeAs('partner_id') partnerId?: string;
  @autoserializeAs('vehicle_id') vehicleId?: string;
  @autoserializeAs('store_id') storeId?: string;
  @autoserializeAs('termination_reason') terminationReason?: string;
  @autoserializeAs('termination_note') terminationNote?: string;
  @autoserializeAs('route_order') order?: number;
  @autoserializeAs('cancellation_fee') cancellationFee?: boolean;
  @autoserializeAs('booking_fee_refund') bookingFeeRefund?: boolean;
  @autoserializeAs('manual_payment') manualPayment?: boolean;
  @autoserialize notify?: boolean;
  @autoserializeAs('adjusted_specification')
  adjustedSpecification?: Specification;
  @autoserializeAs('specification_description')
  specificationDescription?: string;

  constructor(payload: IDonationState) {
    this.stateTarget = payload?.stateTarget;
    this.stateAction = payload?.stateAction;
    this.partnerId = payload?.partnerId?.toString();
    this.vehicleId = payload?.vehicleId?.toString();
    this.storeId = payload?.storeId?.toString();
    this.notify = payload?.notify;
    this.terminationReason = payload?.terminationReason;
    this.terminationNote = payload?.terminationNote;
    this.order = payload?.order;
    this.cancellationFee = payload?.cancellationFee;
    this.bookingFeeRefund = payload?.bookingFeeRefund;
    this.manualPayment = payload?.manualPayment;
    this.adjustedSpecification = payload?.adjustedSpecification
      ? new Specification(payload?.adjustedSpecification)
      : undefined;
    this.specificationDescription = payload?.specificationDescription;
  }
}

export interface IDonationState {
  stateTarget?: string;
  stateAction: string;
  partnerId?: string;
  vehicleId?: string;
  storeId?: string;
  notify?: boolean;
  terminationReason?: string;
  terminationNote?: string;
  order?: number;
  cancellationFee?: boolean;
  bookingFeeRefund?: boolean;
  manualPayment?: boolean;
  adjustedSpecification?: ISpecification;
  specificationDescription?: string;
}

<div class="filterable-list-wrapper" fxLayout="column">
  <div class="filter">
    <input
      [(ngModel)]="filter"
      matInput
      autocomplete="off"
      placeholder="Search"
    />
  </div>
  <ul>
    <ng-container
      *ngFor="let elem of filteredData; let i = index"
      [ngTemplateOutlet]="elementTemplate"
      [ngTemplateOutletContext]="{ element: elem, index: i }"
    ></ng-container>
    <li class="no-results" *ngIf="filteredData.length === 0" fxLayoutAlign="center center">No results</li>
  </ul>
</div>

// tslint:disable: max-line-length
import { Action, createReducer } from '@ngrx/store';

export interface PageState {
}

const initialState: PageState = {};

const reducer = createReducer(
  initialState,
);

export function pageReducer(state: PageState | undefined, action: Action) {
  return reducer(state, action);
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DonationsService,
  LeadsService,
  NotificationsService,
} from '@rspl-api';
import {
  NotificationTargetType,
  NotificationTrigger,
} from '@domains';
import * as fromActions from './donation.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class DonationEffects {
  getDonations = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getDonations),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((response) => {
            return fromActions.getDonationsSuccess({
              resultsCount: response.totalResults,
              results: response.results,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getLeads = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getLeads),
      switchMap((params) =>
        this.leadService.filter(params.findParams).pipe(
          map((response) => {
            return fromActions.getDonationsSuccess({
              resultsCount: response.totalResults,
              results: response.results,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getDonation = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getDonation),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getDonationSuccess({
              donation: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getLead = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getLead),
      switchMap((params) =>
        this.leadService.find(params.id).pipe(
          map((result) => {
            return fromActions.getDonationSuccess({
              donation: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getDonationByCode = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getDonationByCode),
      switchMap((params) =>
        this.service.getDonationByCode(params.donationCode).pipe(
          map((result) => {
            return fromActions.getDonationByCodeSuccess({
              donation: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  createDonation = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createDonation),
      switchMap((params) =>
        this.service.create(params.donation).pipe(
          switchMap((result) => [
            fromActions.createDonationSuccess({
              donation: result,
            }),
            fromActions.getDonation({ id: result.id }),
          ]),
          catchError(() => [fromActions.createDonationError()])
        )
      )
    );
  });

  createLead = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createLead),
      switchMap((params) =>
        this.leadService.create(params.lead).pipe(
          switchMap((result) => [
            fromActions.createLeadSuccess({
              lead: result,
            }),
          ]),
          catchError(() => [fromActions.createLeadError()])
        )
      )
    );
  });

  updateLead = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateLead),
      switchMap((params) =>
        this.leadService.update(params.id, params.lead).pipe(
          switchMap((result) => [
            fromActions.updateLeadSuccess({
              lead: result,
            }),
          ]),
          catchError(() => [fromActions.updateLeadError()])
        )
      )
    );
  });

  updateDonation = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateDonation),
      switchMap((params) =>
        this.service.update(params.id, params.donation).pipe(
          switchMap((result) => [
            fromActions.updateDonationSuccess({
              donation: result,
            }),
            fromActions.getDonation({ id: result.id }),
          ]),
          catchError(() => [fromActions.updateDonationError()])
        )
      )
    );
  });

  convertLeadToDonation = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.convertLeadToDonation),
      switchMap((params) =>
        this.leadService.update(params.id, params.lead).pipe(
          switchMap((result) =>
            this.service.convertLeadToDonation(params.id).pipe(
              switchMap((result) => [
                fromActions.convertLeadToDonationSuccess({
                  donation: result,
                }),
              ]),
              catchError(() => EMPTY)
            )
          ),
          catchError(() => EMPTY)
        )
      )
    );
  });

  updateDonationState = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateDonationState),
      switchMap((params) =>
        this.service.updateDonationState(params.donationId, params.state).pipe(
          map((result) => {
            return fromActions.updateDonationStateSuccess({
              donation: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getPricing = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPricing),
      switchMap((params) =>
        this.leadService.getPricing(params.zip, params.charityId).pipe(
          map((result) => {
            return fromActions.getPricingSuccess({
              pricing: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getHistory),
      switchMap((params) =>
        this.service.history(params.itemIds).pipe(
          map((result) => {
            return fromActions.getHistorySuccess({
              history: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getDeclinedHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getDeclinedHistory),
      switchMap((params) =>
        this.service.getDeclinedHistory(params.donationId).pipe(
          map((result) => {
            return fromActions.getDeclinedHistorySuccess({
              history: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getPaymentReceipt = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPaymentReceipt),
      switchMap((params) =>
        this.service.getPaymentReceipt(params.donationCode).pipe(
          map((result) => {
            return fromActions.getPaymentReceiptSuccess({
              url: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  sendPartnerReminder = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.sendPartnerReminder),
      switchMap((params) =>
        this.notificationsService
          .manualTriggerNotification(
            {
              donation_id: params.donation.id,
              targets: [NotificationTargetType.SMS],
            },
            params.trigger
          )
          .pipe(
            switchMap((result) => [
              fromActions.sendPartnerReminderSuccess({
                donation: params.donation,
                trigger: params.trigger,
              }),
            ]),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: DonationsService,
    private notificationsService: NotificationsService,
    private leadService: LeadsService
  ) {}
}

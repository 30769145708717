<input
  [placeholder]="label"
  #inputElement
  matInput
  type="text"
  readonly
  [value]="showForm ? fullAddress : ''"
  [ngClass]="{ invalid: showError }"
  (click)="openAddress()"
  (keydown.enter)="openAddress()"
/>
<mat-icon svgIcon="location"></mat-icon>
<span
  class="input-error"
  *ngIf="showError && inputElement.value.trim().length === 0"
  ><mat-icon>info_outline</mat-icon>Enter your address to see available
  times.</span
>

<ng-template #addressDialog>
  <form
    fxLayout="column"
    class="addr-dialog"
    matDialogContent
    [formGroup]="form"
  >
    <div fxLayout="column">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex="100"
        fxLayoutGap.gt-sm="10px"
        style="width: 100%"
      >
        <div fxLayout="column" fxFlex="80" fxFlex.lt-md="70">
          <mat-form-field appearance="outline" class="addr-input">
            <mat-label>Street</mat-label>
            <input
              formControlName="street"
              #inputElement
              matInput
              [matAutocomplete]="auto"
              (input)="getAddressesDelayed()"
              autocomplete="off"
            />
            <mat-autocomplete #auto="matAutocomplete" class="addr-autocomplete">
              <mat-option disabled class="loading" *ngIf="loading">
                <div fxFlex="100" fxLayoutAlign="center center">
                  <mat-spinner [diameter]="20"></mat-spinner>
                </div>
              </mat-option>
              <mat-option
                disabled
                class="loading"
                *ngIf="
                  !loading &&
                  addresses.length === 0 &&
                  (form.get('street')?.value?.length || 0) > 0
                "
              >
                No matches
              </mat-option>
              <mat-option
                *ngFor="let addr of addresses; let i = index"
                [value]="addr.street"
                (onSelectionChange)="select(addr, suggestions[i], $event)"
              >
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon>location_pin</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" fxFlex>
                  <p>
                    {{ addr.street }}
                    {{ addr.secondary && '#' + addr.secondary }}
                  </p>
                  <p>{{ addr.city }}, {{ addr.state }} {{ addr.zip }}, US</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div fxLayout="row" class="errors">
            <mat-hint
              class="error"
              *ngIf="form.touched && !showForm && !address.state"
              >You must select one of the offered addresses</mat-hint
            >
            <mat-hint
              class="error"
              *ngIf="
                form.get('street')?.touched &&
                showForm &&
                (form.get('street')?.errors || {})['required']
              "
              >Street is required</mat-hint
            >
            <mat-hint
              class="error"
              *ngIf="
                form.get('street')?.touched &&
                showForm &&
                (form.get('street')?.errors || {})['pattern']
              "
              >Street is not valid</mat-hint
            >
          </div>
        </div>

        <mat-form-field
          appearance="outline"
          fxFlex="20"
          fxFlex.lt-md="30"
          style="margin-bottom: 15px"
        >
          <mat-label>Apt. No.</mat-label>
          <input type="text" matInput formControlName="secondary" />
        </mat-form-field>
      </div>
      <a *ngIf="!showForm" class="enter-manually" (click)="showForm = true"
        >Can't find your address? Click here to enter it manually.</a
      >
      <mat-form-field appearance="outline" fxFlex="100" *ngIf="showForm">
        <mat-label>City</mat-label>
        <input type="text" matInput formControlName="city" (input)="isManualAddress = true"/>
      </mat-form-field>
      <div fxLayout="row" fxLayout.lt-md="column" class="errors" *ngIf="showForm">
        <mat-hint
          class="error"
          *ngIf="
            form.get('city')?.touched &&
            showForm &&
            (form.get('city')?.errors || {})['required']
          "
          >City is required</mat-hint
        >
        <mat-hint
          class="error"
          *ngIf="
            form.get('city')?.touched &&
            showForm &&
            (form.get('city')?.errors || {})['pattern']
          "
          >City is not valid</mat-hint
        >
      </div>
      <rspl-us-states-selector
        *ngIf="showForm"
        [required]="true"
        [stateToEdit]="form.get('state')?.value"
        fxFlex="100"
        (selectedState)="setSelectedState($event)"
        [showError]="
          form.get('state')?.touched &&
          showForm &&
          (form.get('state')?.errors || {})['required']
        "
      ></rspl-us-states-selector>
      <div fxLayout="row" fxLayout.lt-md="column" class="errors" *ngIf="showForm">
        <mat-hint
          class="error"
          *ngIf="
            form.get('state')?.touched &&
            showForm &&
            (form.get('state')?.errors || {})['required']
          "
          >State is required</mat-hint
        >
      </div>
      <mat-form-field appearance="outline" fxFlex="100" *ngIf="showForm">
        <mat-label>Zip</mat-label>
        <input type="text" matInput formControlName="zip"  (input)="isManualAddress = true"/>
      </mat-form-field>
      <div fxLayout="row" fxLayout.lt-md="column" class="errors" *ngIf="showForm">
        <mat-hint
          class="error"
          *ngIf="
            form.get('zip')?.touched &&
            showForm &&
            (form.get('zip')?.errors || {})['required']
          "
          >Zip is required</mat-hint
        >
        <mat-hint
          class="error"
          *ngIf="
            form.get('zip')?.touched &&
            showForm &&
            (form.get('zip')?.errors || {})['pattern']
          "
          >Zip is not valid</mat-hint
        >
      </div>
    </div>
  </form>
  <div
    fxLayout="row"
    fxLayoutAlign="end"
    fxLayoutAlign.lt-md="center"
    matDialogActions
  >
    <button class="submit-button primary-page-btn" (click)="confirmAddress()">
      Confirm
    </button>
  </div>
</ng-template>

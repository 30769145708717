import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rspl-select-photo',
  templateUrl: './select-photo.component.html',
  styleUrls: ['./select-photo.component.scss']
})
export class SelectPhotoComponent implements OnInit {

  constructor(
    public reference: MatDialogRef<SelectPhotoComponent>,
    @Inject(MAT_DIALOG_DATA) public photos: string[]
  ) {
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.reference.close(undefined);
  }

  public upload(): void {
    this.reference.close(null);
  }

  public select(image: string): void {
    this.reference.close(image);
  }

}

import { Action, createReducer, on } from '@ngrx/store';
import { Xmile } from '@domains';
import * as fromActions from './xmile.actions';

export interface XmileState {
  xmiles: Array<Xmile>;
  xmilesTotalCount: number;
  xmile: Xmile;
}

const initialState: XmileState = {
  xmiles: new Array<Xmile>(),
  xmilesTotalCount: 0,
  xmile: new Xmile(),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createXmileSuccess, (state, action: any) => ({...state, xmile: action.xmile})),
  on(fromActions.updateXmileSuccess, (state, action: any) => ({...state, xmile: action.xmile})),

  on(fromActions.getXmiles, (state, {findParams, keep}) => {
    return {...state, xmiles: keep ? state.xmiles : []};
  }),
  on(fromActions.getXmilesSuccess, (state, {xmiles, resultsCount}) => ({...state, xmiles, xmilesTotalCount: resultsCount})),
  on(fromActions.clearXmilesFindParams, state => {
    return {...state};
  }),

  on(fromActions.getXmile, (state, {id, keep}) => ({...state, xmile: keep ? state.xmile : null})),
  on(fromActions.getXmileSuccess, (state, action: any) => ({...state, xmile: action.xmile})),
);

export function xmileReducer(state: XmileState | undefined, action: Action) {
  return reducer(state, action);
}

export class PageAction {
  actionTitle: string;
  icon: string | {name: string, svg: boolean};
  cssClass?: string;
  actionMethod?: () => void;
  link?: string[];
  externalLink?: string;
  externalLinkTarget?: string;
  hidden?: ((x?: any) => boolean) = () => false;
  disabled?: ((x?: any) => boolean) = () => false;

  constructor(action: PageAction) {
    this.actionTitle = action.actionTitle;
    this.cssClass = action.cssClass;
    this.actionMethod = action.actionMethod;
    this.link = action.link;
    this.externalLink = action.externalLink;
    this.externalLinkTarget = action.externalLinkTarget || '_blank';
    this.hidden = action.hidden || (() => false);
    this.disabled = action.disabled || (() => false);
    this.icon = action.icon;
  }
}

import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, map } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[rsplDoubleClick]'
})
export class DoubleClickDirective implements OnInit, OnDestroy {
  private click$ = new Subject<MouseEvent>();

  @Output()
  rsplDoubleClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    this.click$.next(event);
  }

  ngOnInit(): void {
    this.click$
      .pipe(
        buffer(this.click$.pipe(debounceTime(250))),
        filter(list => list.length === 2),
        map(list => list[1])
      )
      .subscribe(this.rsplDoubleClick);
  }

  ngOnDestroy(): void {
    this.click$.complete();
  }
}

// tslint:disable: max-line-length
import { FindParamsDefault, Partner } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './partner.actions';

export interface PartnerState {
  partners: Array<Partner>;
  partnersTotalCount: number;
  partner: Partner;
  createdPartner: Partner;
  updatedPartner: Partner;
  deletedPartnerId: number;
  findParams: { [key: string]: FindParamsDefault };
  partnersForFeatureFlags: Array<Partner>;
  partnersForFeatureFlagsTotalCount: number;
  findParamsForFeatureFlags: { [key: string]: FindParamsDefault };
}

const initialState: PartnerState = {
  partners: new Array<Partner>(),
  partnersTotalCount: 0,
  partner: undefined,
  createdPartner: undefined,
  updatedPartner: undefined,
  deletedPartnerId: 0,
  findParams: JSON.parse(localStorage.getItem('PartnerFindParams') || '{}'),
  partnersForFeatureFlags: new Array<Partner>(),
  partnersForFeatureFlagsTotalCount: 0,
  findParamsForFeatureFlags: JSON.parse(
    localStorage.getItem('PartnerForFeatureFlagsFindParams') || '{}'
  ),
};

const reducer = createReducer(
  initialState,

  on(fromActions.getPartners, (state, { findParams, keep }) => {
    const newValue = { ...state.findParams };
    newValue[window.location.pathname] = findParams;
    return {
      ...state,
      partnersTotalCount: keep ? state.partnersTotalCount : 0,
      partners: keep ? state.partners : [],
      findParams: newValue,
    };
  }),
  on(fromActions.getPartnersSuccess, (state, { resultsCount, results }) => ({
    ...state,
    partnersTotalCount: resultsCount,
    partners: results,
  })),
  on(fromActions.clearPartnersFindParams, (state) => {
    const newValue = { ...state.findParams };
    newValue[window.location.pathname] = {};
    return { ...state, findParams: newValue };
  }),

  on(fromActions.getPartner, (state, action: any) => ({
    ...state,
    partner: null,
  })),
  on(fromActions.getPartnerSuccess, (state, action: any) => ({
    ...state,
    partner: new Partner(action.partner),
  })),

  on(fromActions.createPartnerSuccess, (state, action: any) => ({
    ...state,
    createdPartner: action.partner,
  })),

  on(fromActions.updatePartnerSuccess, (state, action: any) => ({
    ...state,
    updatedPartner: action.partner,
  })),

  on(fromActions.deletePartnerSuccess, (state, action: any) => ({
    ...state,
    deletedPartnerId: action.partnerId,
  })),

  on(fromActions.getPartnersForFeatureFlags, (state, { findParams, keep }) => {
    const newValue = { ...state.findParamsForFeatureFlags };
    newValue[window.location.pathname] = findParams;
    return {
      ...state,
      partnersForFeatureFlagsTotalCount: keep ? state.partnersForFeatureFlagsTotalCount : 0,
      partnersForFeatureFlags: keep ? state.partnersForFeatureFlags : [],
      findParamsForFeatureFlags: newValue,
    };
  }),
  on(fromActions.getPartnersForFeatureFlagsSuccess, (state, { resultsCount, results }) => ({
    ...state,
    partnersForFeatureFlagsTotalCount: resultsCount,
    partnersForFeatureFlags: results,
  })),
  on(fromActions.clearPartnersForFeatureFlagsFindParams, (state) => {
    const newValue = { ...state.findParamsForFeatureFlags };
    newValue[window.location.pathname] = {};
    return { ...state, findParamsForFeatureFlags: newValue };
  }),

  on(fromActions.updateForFeatureFlagsPartnerSuccess, (state, action: any) => {
    const partnersForFeatureFlags = [...state.partnersForFeatureFlags];
    const i = partnersForFeatureFlags.findIndex(x => x.id === action.partner.id);
    partnersForFeatureFlags[i] = action.partner;
    return { ...state, partnersForFeatureFlags };
  })
);
export function partnerReducer(
  state: PartnerState | undefined,
  action: Action
) {
  return reducer(state, action);
}

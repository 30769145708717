<section class="ngx-json-viewer">
  <section
    *ngFor="let segment of segments"
    [ngClass]="['segment', 'segment-type-' + segment.type]"
  >
    <section
      (click)="toggle(segment)"
      [ngClass]="{
        'segment-main': true,
        expandable: isExpandable(segment),
        expanded: segment.expanded,
        clickable: !isExpandable(segment)
      }"
    >
      <div *ngIf="isExpandable(segment)" class="toggler"></div>
      <span class="segment-key">{{ segment.key }}</span>
      <span class="segment-separator" *ngIf="!isExpandable(segment)">: </span>
      <span
        *ngIf="!segment.expanded || !isExpandable(segment)"
        class="segment-value"
        >{{
          ["object", "array"].includes(segment.type || "")
            ? ""
            : segment.description
        }}</span
      >
    </section>
    <section *ngIf="segment.expanded && isExpandable(segment)" class="children">
      <rspl-json-viewer
        *ngIf="segment.type"
        [json]="segment.value"
        [expanded]="expanded"
        [depth]="depth"
        [_currentDepth]="_currentDepth + 1"
        [path]="
          path +
          (parentType === 'array'
            ? '[' + segment.key + ']'
            : this.parentType === 'object'
            ? '.' + segment.key
            : segment.key)
        "
        [parentType]="segment.type"
        (onSelect)="onSelect.emit($event)"
      >
      </rspl-json-viewer>
    </section>
  </section>
</section>

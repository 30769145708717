<mat-form-field
  class="zone-input"
  appearance="outline"
  [ngClass]="{ invalid: !disabled && required && !(zips?.length > 0) }"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList [disabled]="disabled">
    <mat-chip
      *ngFor="let zip of zips"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(zip)"
      [disabled]="disabled"
    >
      {{ zip }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      [matChipInputFor]="chipList"
      [matChipInputAddOnBlur]="false"
      (matChipInputTokenEnd)="add($event)"
      (paste)="paste($event)"
      [readOnly]="disabled"
    />
  </mat-chip-list>
  <mat-icon
    (click)="copy()"
    *ngIf="zips?.length > 0 && (responsive.size | async) !== windowSizes.XS"
    class="copy"
    title="Copy zips to clipboard"
    matSuffix
    >content_copy
  </mat-icon>
</mat-form-field>
<p
  class="error"
  fxLayout="row"
  *ngIf="showErrorMessage && !disabled && required && !(zips?.length > 0)"
>
  Zips are required
</p>
<div fxLayout="row">
  <button
    class="primary-color"
    mat-button
    ngClass.xs="btn-block"
    style="margin-bottom: 20px"
    (click)="copy()"
    *ngIf="zips?.length > 0 && (responsive.size | async) === windowSizes.XS"
  >
    Copy zips to clipboard
  </button>
</div>

// tslint:disable: max-line-length
import { FindParamsDefault, Item } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './item.actions';

export interface ItemState {
  items: Array<Item>;
  itemsTotalCount: number;
  item: Item;
  createdItem: Item;
  updatedItem: Item;
  deletedItemId: number;
  findParams: { [key: string]: FindParamsDefault };
}

const initialState: ItemState = {
  items: new Array<Item>(),
  itemsTotalCount: 0,
  item: undefined,
  createdItem: undefined,
  updatedItem: undefined,
  deletedItemId: 0,
  findParams: JSON.parse(localStorage.getItem('ItemFindParams') || '{}'),
};

const reducer = createReducer(
  initialState,

  on(fromActions.getItems, (state, {findParams, keep}) => {
    const newValue = {...state.findParams};
    newValue[window.location.pathname] = findParams;
    return {...state, itemsTotalCount: keep ? state.itemsTotalCount : 0, items: keep ? state.items : [], findParams: newValue};
  }),
  on(fromActions.getItemsSuccess, (state, {resultsCount, results}) => ({
    ...state,
    itemsTotalCount: resultsCount,
    items: results
  })),
  on(fromActions.clearItems, (state) => ({
    ...state,
    itemsTotalCount: 0,
    items: new Array<Item>()
  })),
  on(fromActions.clearItemsFindParams, state => {
    const newValue = {...state.findParams};
    newValue[window.location.pathname] = {};
    return {...state, findParams: newValue};
  }),

  on(fromActions.getItem, (state, action: any) => ({...state, item: null})),
  on(fromActions.getItemSuccess, (state, action: any) => ({...state, item: new Item(action.item)})),

  on(fromActions.createItemSuccess, (state, action: any) => ({...state, createdItem: action.item})),

  on(fromActions.updateItemSuccess, (state, action: any) => ({...state, updatedItem: action.item})),

  on(fromActions.deleteItemSuccess, (state, action: any) => ({...state, deletedItemId: action.itemId})),

);
export function itemReducer(state: ItemState | undefined, action: Action) {
  return reducer(state, action);
}

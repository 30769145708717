<div class="default-filters">
  <mat-accordion *ngIf="smallWindowSizes.includes(windowSize)">
    <mat-expansion-panel [disabled]="editMode">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
          <mat-icon *ngIf="!isEmpty" class="has-filters-flag">circle</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="filters"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="filters-wrapper" *ngIf="!smallWindowSizes.includes(windowSize)">
    <ng-container [ngTemplateOutlet]="filters"></ng-container>
  </div>

  <ng-template #filters>
    <form
    *ngIf="form"
      [formGroup]="form"
      fxLayout="row wrap"
      fxLayoutGap="10px"
      fxLayoutAlign="left"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="0"
    >
      <div fxFlex="32" fxLayout="row" fxLayoutGap="1" *ngIf="isCaptain">
        <mat-button-toggle-group fxFlex="100">
          <mat-button-toggle
            fxFlex="50"
            [disabled]="editMode || disabled"
            [checked]="!inactive"
            (click)="setInactive(false)"
          >
            Active
          </mat-button-toggle>
          <mat-button-toggle
            fxFlex="50"
            [disabled]="editMode || disabled"
            [checked]="inactive"
            (click)="setInactive(true)"
          >
            Inactive
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxFlex="32" fxLayout="column" fxLayoutGap="1">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Search by ID</mat-label>
          <input formControlName="id" matInput autocomplete="off" />
        </mat-form-field>
      </div>
      <div fxFlex="32" fxLayout="column" fxLayoutGap="1">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Search by Name</mat-label>
          <input formControlName="name" matInput autocomplete="off" />
        </mat-form-field>
      </div>

      <button
        class="clear-filters-btn"
        mat-icon-button
        (click)="resetForm()"
        title="Clear all filters"
        type="button"
        [disabled]="editMode || isEmpty || disabled"
        *ngIf="!smallWindowSizes.includes(windowSize)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-raised-button
        (click)="resetForm()"
        title="Clear all filters"
        type="button"
        [disabled]="editMode || isEmpty || disabled"
        *ngIf="smallWindowSizes.includes(windowSize)"
      >
        Clear all filters
      </button>
    </form>
  </ng-template>
</div>

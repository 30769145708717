import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'rspl-filterable-list',
  templateUrl: './filterable-list.component.html',
  styleUrls: ['./filterable-list.component.scss'],
})
export class FilterableListComponent implements OnInit {
  @Input() additionalData: any;
  #data: Array<any>; 
  @Input() set data(data: Array<any>) {
    this.#data = data;
    this.filterData();
  }
  get data(): Array<any> {
    return this.#data;
  }
  @Input() elementTemplate: TemplateRef<any>;
  @Input() filterFn: (elements: Array<any>, filter: string, additionalData?: any) => Array<any> = (
    elements,
    filter,
    additionalData
  ) =>
    filter?.length > 0
      ? elements?.filter((e) => e['name'].includes(filter))
      : elements;
  filteredData: Array<any>;

  #filter = '';
  set filter(filter: string) {
    this.#filter = filter;

    this.filterData();
  }
  get filter(): string {
    return this.#filter;
  }

  constructor() {}

  filterData() {
    this.filteredData = this.filterFn
      ? this.filterFn(this.data, this.filter, this.additionalData)
      : this.data;
  }

  ngOnInit(): void {}
}

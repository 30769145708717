import { Injectable } from '@angular/core';
import { Market } from '@domains';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MarketsService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from './market.actions';

@Injectable()
export class MarketEffects {
  createMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createMarket),
      mergeMap((payload) =>
        this.service.create(payload.market).pipe(
          map((result) => {
            return fromActions.createMarketSuccess({
              market: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  updateMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateMarket),
      mergeMap((payload) => {
        return this.service.update(payload.market.id, payload.market).pipe(
          map((result) => {
            return fromActions.updateMarketSuccess({
              market: result,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  getMarkets = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getMarkets),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((response) => {
            return fromActions.getMarketsSuccess({
              markets: response.results,
              resultsCount: response.totalResults,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getMarket),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getMarketSuccess({
              market: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  deleteMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteMarket),
      switchMap((params) =>
        this.service.delete(params.marketId).pipe(
          map((result) => {
            return fromActions.deleteMarketSuccess({
              marketId: params.marketId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  restoreMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreMarket),
      switchMap((params) =>
        this.service.restore(params.marketId).pipe(
          map((result) => {
            return fromActions.restoreMarketSuccess({
              marketId: params.marketId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getMarketsForFeatureFlags = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getMarketsForFeatureFlags),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getMarketsForFeatureFlagsSuccess({
              resultsCount: results.totalResults,
              results: results.results.map((p) => new Market(p)),
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  updateForFeatureFlagsMarket = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateForFeatureFlagsMarket),
      switchMap((payload) =>
        this.service.update(payload.market.id, payload.market).pipe(
          map((result) => {
            return fromActions.updateForFeatureFlagsMarketSuccess({
              market: result,
            });
          }),
          catchError(() => [
            fromActions.updateForFeatureFlagsMarketFail({
              market: payload.market,
            }),
          ])
        )
      )
    );
  });

  constructor(private actions$: Actions, private service: MarketsService) {}
}

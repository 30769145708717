import { Component, Inject, OnInit } from '@angular/core';
import { Theme, AppType } from '@domains';
import { takeUntil } from 'rxjs';
import { Destructible } from '../../destructible';
import { Environment, ENVIRONMENT } from '@domains';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'rspl-theme-button',
  templateUrl: './theme-button.component.html',
  styleUrls: ['./theme-button.component.scss'],
})
export class ThemeButtonComponent extends Destructible implements OnInit {
  theme: Theme = Theme.LIGHT;
  themes = Theme;
  autoTheme?: boolean;
  app: AppType;

  constructor(
    private themeService: ThemeService,
    @Inject(ENVIRONMENT) private config: Environment,
  ) {
    super();
    this.app = this.config.app;
  }

  ngOnInit(): void {
    this.themeService.theme.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.theme = data.theme || Theme.LIGHT;
      this.autoTheme = data.auto;
    });
  }

  toggleTheme(): void {
    if (this.autoTheme) {
      this.themeService.setTheme(Theme.LIGHT);
    } else if (this.theme === Theme.LIGHT) {
      this.themeService.setTheme(Theme.DARK);
    } else {
      this.themeService.setTheme();
    }
  }
}

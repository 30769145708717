import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'rspl-us-states-selector',
  templateUrl: './us-states-selector.component.html',
  styleUrls: ['./us-states-selector.component.scss'],
})
export class UsStatesSelectorComponent implements OnInit, OnChanges {
  @Output() selectedState = new EventEmitter();
  @Input() stateToEdit?: string | null;
  @Input() showError = false;
  #required = false;
  @Input() set required(required: boolean) {
    this.#required = required;
    this.stateControl.setValidators(this.required ? [Validators.required] : []);
  }

  #disabled = false;
  @Input() set disabled(disabled: boolean) {
    this.#disabled = disabled;
    if (this.disabled) {
      this.stateControl?.disable();
    } else {
      this.stateControl.enable();
    }
  }
  get disabled(): boolean {
    return this.#disabled;
  }

  get required(): boolean {
    return this.#required;
  }

  stateControl = new FormControl<{
    name: string;
    abbreviation: string;
  } | null>(null);
  stateFilterControl = new FormControl<string>('');
  filteredOptions?: Observable<
    {
      name: string;
      abbreviation: string;
    }[]
  >;

  constructor(@Inject(ENVIRONMENT) private config: Environment) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stateToEdit && states.length) {
      this.filter();
      const state = states.find(
        (state) => state.abbreviation === this.stateToEdit
      );
      if (state) this.stateControl.setValue(state);
    }
  }

  ngOnInit(): void {
    this.filter();
  }

  private filter(): void {
    this.filteredOptions = this.stateFilterControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (typeof value === 'string') {
          return this._filter(value);
        } else {
          return [];
        }
      })
    );
  }

  private _filter(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return states.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return states;
    }
  }

  public stateSelected(): void {
    this.selectedState.emit(this.stateControl.value);
    this.stateFilterControl.patchValue('');
  }

  public getStateName(selectedState: any): string {
    if (selectedState && states.length) {
      return (
        states.find(
          (state) => state.abbreviation === selectedState.abbreviation
        )?.abbreviation || ''
      );
    } else {
      return '';
    }
  }

  focusFilter(filterInput: HTMLInputElement, $event: boolean): void {
    if ($event) {
      filterInput.focus();
    }
  }

  clear(event: MouseEvent): void {
    this.stateControl.setValue(null);
    this.stateSelected();
    event.stopPropagation();
  }
}

export const states = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'DC',
    abbreviation: 'DC',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Address, ENVIRONMENT, Environment } from '@domains';
import {
  AutofillSuggestion,
  MapboxAutofill,
  MapboxGeocode,
  MapboxSearch,
  SearchSession,
  SessionToken,
} from '@mapbox/search-js-core';
import {
  DesignService,
  Designable,
  ResponsiveService,
  UsStatesSelectorComponent,
  states,
} from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'rspl-address-input-form',
  templateUrl: './address-input-form.component.html',
  styleUrls: ['./address-input-form.component.scss'],
})
export class AddressInputFormComponent extends Designable {
  @ViewChild('addressDialog') addressDialogTemplate!: TemplateRef<any>;
  @ViewChild('inputElement') inputEl: any;
  @ViewChildren('li') addrLis: any[] = [];
  @Output() addressChange: EventEmitter<{address: Address, manual: boolean}> = new EventEmitter<{address: Address, manual: boolean}>();
  @Output() addressError: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddressPopupOpened: EventEmitter<void> = new EventEmitter<void>();
  @Input() label = 'Your address';
  @Input() readonly = false;
  @Input() showError = false;
  @Input() leadId?: string;
  @Input() set disabled(disabled: boolean) {
    if (disabled) this.form.disable();
    else this.form.enable();
  }
  #stateSelect?: UsStatesSelectorComponent;
  @ViewChild(UsStatesSelectorComponent) set stateSelect(
    stateSelect: UsStatesSelectorComponent | undefined
  ) {
    this.#stateSelect = stateSelect;
    if (this.form.get('city')?.touched) {
      this.stateSelect?.stateControl.markAsTouched();
      this.stateSelect?.stateControl.updateValueAndValidity();
    }
  }

  get stateSelect(): UsStatesSelectorComponent | undefined {
    return this.#stateSelect;
  }
  savedAddress?: Address;
  delay: any;
  mapBoxAccessToken: string;

  addressDialog?: MatDialogRef<any>;

  autofill: MapboxAutofill;
  suggestions: AutofillSuggestion[] = [];
  geocoding: MapboxGeocode;
  sessionToken: SessionToken;

  search: MapboxSearch;
  session;

  showForm = false;
  isManualAddress = false;

  form = new FormGroup({
    street: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d+\s+[\w\-]+(\s[\w\-]+)*(\s[\w&'\-]+)?$/),
    ]),
    secondary: new FormControl<string>(''),
    city: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^[\w\s\-']+$/),
    ]),
    state: new FormControl<string>('', [Validators.required]),
    zip: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d{5}(-\d{4}){0,1}$/),
    ]),
    lat: new FormControl<number | null>(null),
    lng: new FormControl<number | null>(null),
  });

  @Input() set address(address: Address | undefined) {
    if (address && (address?.street?.length || 0) > 0) {
      if (
        (address.street?.length || 0) > 0 &&
        address.street !== this.address.street
      ) {
        this.getAddresses(address.street);
      } else {
      }
      this.form.patchValue(address);
      if (address.zip) {
        this.showForm = true;
      }
      this.savedAddress = { ...address } as Address;
    }
  }

  get address(): Address {
    return this.form.getRawValue() as Address;
  }

  addresses: Address[] = [];
  loading = false;

  constructor(
    private dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private config: Environment
  ) {
    super(designService, responsiveService);
    this.mapBoxAccessToken = this.config.mapBoxAccessToken;
    this.autofill = new MapboxAutofill({
      accessToken: this.config.mapBoxAccessToken,
      language: 'en',
      country: 'us',
      proximity: 'ip',
    });
    this.geocoding = new MapboxGeocode({
      accessToken: this.config.mapBoxAccessToken,
      language: 'en',
      types: new Set(['address']),
    });
    this.sessionToken = new SessionToken();

    this.search = new MapboxSearch({
      accessToken: this.config.mapBoxAccessToken,
      types: new Set(['address']),
    });
    this.session = new SearchSession(this.search);
  }

  openAddress() {
    this.addressDialog = this.dialog.open(this.addressDialogTemplate, {
      width: '100vw',
      maxWidth: '600px',
      maxHeight: '100vh',
      panelClass: 'address-input-form-dialog',
    });
    this.onAddressPopupOpened.emit();
    this.addressDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (this.savedAddress)
          this.address = { ...this.savedAddress } as Address;
      });
  }

  getAddressesDelayed() {
    this.isManualAddress = true;
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.getAddresses(this.address.street);
    }, 300);
  }

  async getAddresses(street: string | undefined | null) {
    this.addresses = [];
    if (street?.trim().length === 0) return;
    this.loading = true;

    this.suggestions = await (
      await this.autofill.suggest(street || '', {
        sessionToken: this.sessionToken,
      })
    ).suggestions;
    if (this.suggestions.length > 0) {
      for (const suggestion of this.suggestions) {
        this.addresses.push({
          street: suggestion.address_line1,
          city: suggestion.address_level2,
          state: suggestion.address_level1,
          zip: suggestion.postcode,
        } as Address);
      }
    }
    this.loading = false;
  }

  formatAddress(address?: Address): string {
    return address && (address?.street?.length || 0) > 0
      ? `${address.street || ''}${
          address.secondary ? ' #' + address.secondary : ''
        } ${address.city || ''}, ${address.state || ''} ${
          address.zip || ''
        }, USA`
      : '';
  }

  select(
    addr: Address,
    suggestion: AutofillSuggestion,
    event: MatOptionSelectionChange
  ) {
    if (!event.isUserInput) return;
    this.showForm = true;
    this.isManualAddress = false;
    this.form.patchValue(addr);
    if (!this.showError) {
      this.autofill
        .retrieve(suggestion, {
          sessionToken: this.sessionToken,
        })
        .then((res) => {
          const features = res.features;
          this.form.patchValue({
            ...addr,
            lng: features[0].geometry.coordinates[0],
            lat: features[0].geometry.coordinates[1],
          });
        });
    }
  }

  confirmAddress() {
    this.form.markAsTouched();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.stateSelect?.stateControl.markAsTouched();
    this.stateSelect?.stateControl.updateValueAndValidity();
    if (this.form.invalid) {
      this.setAddressError();
      return;
    }

    this.addressChange.emit({
      address: this.address,
      manual: this.isManualAddress
    });
    this.addressDialog?.close();
  }

  setAddressError() {
    this.addressError.emit(this.formatAddress(this.address));
  }

  formatState(state?: string): string {
    return state
      ? states.find((s) => s.abbreviation === state)?.name || '/'
      : '/';
  }

  get fullAddress() {
    return this.savedAddress
      ? `${this.savedAddress.street || ''}${
          this.savedAddress.secondary ? ' #' + this.savedAddress.secondary : ''
        } ${this.savedAddress.city || ''}, ${this.savedAddress.state || ''} ${
          this.savedAddress.zip || ''
        }, USA`
      : '';
  }

  public setSelectedState(e: { name: string; abbreviation: string }): void {
    this.isManualAddress = true;
    this.form.get('state')?.patchValue(e.abbreviation);
  }
}

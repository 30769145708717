import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  HistoryComponent,
  HistoryConfig,
  HistoryEntity,
  HISTORY_CONFIGURATION,
} from '@rspl-ui';

@Component({
  selector: 'rspl-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss'],
})
export class HistoryPageComponent implements OnInit {
  config?: HistoryConfig;
  @ViewChild(HistoryComponent) history?: HistoryComponent;

  entities = Object.values(HistoryEntity);
  form = new FormGroup({
    entity: new FormControl<HistoryEntity | null>(null, Validators.required),
    id: new FormControl<string | null>(null, Validators.required),
  });

  constructor() {}

  ngOnInit(): void {}

  fetch() {
    if (this.id)
      this.config = {
        itemIds: [this.id],
        ...HISTORY_CONFIGURATION[this.entity],
      };
  }

  get entity(): HistoryEntity {
    return this.form.get('entity')?.value as HistoryEntity;
  }

  get id(): string | null | undefined {
    return this.form.get('id')?.value;
  }
}

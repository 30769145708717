<mat-form-field appearance="outline" fxFlex="100" class="pick-partner-wrapper" [ngClass]="{'mat-form-field-invalid': showError && ((!multiple && !selected) || (multiple && selected?.length === 0))}">
  <mat-label>Select Truck{{multiple ? 's' : ''}}</mat-label>
  <mat-icon *ngIf="multiple" matPrefix [ngStyle]="{opacity: disabled ? 0.5 : 1}">search</mat-icon>
  <mat-select matInput
              [(value)]="selected"
              (selectionChange)="trucksSelected($event.value)"
              panelClass="pick-partner"
              disableOptionCentering="true"
              [disabled]="disabled"
              [multiple]="multiple"
              [required]="required"
              (openedChange)="focusFilter(filterInput, $event)"
  >
    <input type="text"
           placeholder="Search..."
           matInput
           class="picker-filter"
           autocomplete="off"
           name="partner-picker-filter"
           #filterInput
           [formControl]="filter"
           (input)="filterDelayed()"
           (keydown.space)="$event.stopPropagation()">
    <button *ngIf="multiple && visibleTrucks.length > 0" mat-button class="select-all btn-block"
            (click)="toggleAll()">{{selected.length !== visibleTrucks.length ? 'Select All' : 'Deselect All'}}</button>
    <ng-container *ngFor="let marketId of marketIds">
      <div *ngIf="multiple && (marketsCount === 0 || markets[marketId])">
        <p *ngIf="multiple && markets[marketId] && (partnerIds[marketId]).length > 0" class="market">
          <mat-checkbox [checked]="isMarketSelected(marketId)" (change)="toggleMarket(marketId)">
            {{markets && markets[marketId?.toString()] ? markets[marketId?.toString()].name : 'Unassigned'}}
          </mat-checkbox>
        </p>
        <div *ngFor="let partnerId of partnerIds[marketId]">
          <p *ngIf="multiple && truckIds[marketId][partnerId].length > 0" class="partner">
            <mat-checkbox [checked]="isPartnerSelected(marketId, partnerId)"
                          (change)="togglePartner(marketId, partnerId)">
              {{partners && partners[partnerId] ? partners[partnerId].name : ''}}
            </mat-checkbox>
            </p>
          <mat-option *ngFor="let option of truckIds[marketId][partnerId]" [value]="option.id">
            {{option.name}}
          </mat-option>
        </div>
      </div>
    </ng-container>
  </mat-select>
  <mat-icon *ngIf="!required && ((multiple && selected?.length > 0) || (!multiple && !!selected))" matSuffix (click)="clear($event);">close</mat-icon>
</mat-form-field>

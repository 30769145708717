import { createAction, props } from '@ngrx/store';
import {
  Donation,
  DonationStateDTO,
  FindDonationsParams,
  FindLeadsParams,
  HistoryEvent,
  Lead,
  NotificationTrigger,
  Pricing,
} from '@domains';

export enum DonationActionType {
  ClearDonations = '[Captain] Clear Donations',
  GetDonations = '[Captain] Get Donations',
  GetDonationsSuccess = '[Captain] Get Donations Success',

  GetLeads = '[Captain] Get Leads',

  GetDonation = '[Captain] Get Donation',
  GetDonationSuccess = '[Captain] Get Donation Success',
  GetLead = '[Captain] Get Lead',

  GetDonationByCode = '[Captain] Get Donation By Code',
  GetDonationByCodeSuccess = '[Captain] Get Donation By Code Success',

  CreateDonation = '[Captain] Create Donation',
  CreateDonationSuccess = '[Captain] Create Donation Success',
  CreateDonationError = '[Captain] Create Donation Error',

  CreateLead = '[Captain] Create Lead',
  CreateLeadSuccess = '[Captain] Create Lead Success',
  CreateLeadError = '[Captain] Create Lead Error',

  UpdateDonation = '[Captain] Update Donation',
  UpdateDonationSuccess = '[Captain] Update Donation Success',
  UpdateDonationError = '[Captain] Update Donation Error',

  UpdateLead = '[Captain] Update Lead',
  UpdateLeadSuccess = '[Captain] Update Lead Success',
  UpdateLeadError = '[Captain] Update Lead Error',

  ConvertLeadToDonation = '[Captain] Convert Lead To Donation',
  ConvertLeadToDonationSuccess = '[Captain] Convert Lead To Donation Success',

  UpdateDonationState = '[Captain] Update Donation State',
  UpdateDonationStateSuccess = '[Captain] Update Donation State Success',

  GetPricing = '[Captain] Get Pricing',
  GetPricingSuccess = '[Captain] Get Pricing Success',

  GetHistory = '[Captain] Get History',
  GetHistorySuccess = '[Captain] Get History Success',

  GetDeclinedHistory = '[Captain] Get Declined History',
  GetDeclinedHistorySuccess = '[Captain] Get Declined History Success',

  GetPaymentReceipt = '[Captain] Get Payment Receipt',
  GetPaymentReceiptSuccess = '[Captain] Get Payment Receipt Success',

  SetPendingDonationsCount = '[Captain] Set Pending Donations Count',

  SendPartnerReminder = '[Captain] Send Partner Reminder',
  SendPartnerReminderSuccess = '[Captain] Send Partner Reminder Success',
}

export const clearDonations = createAction(DonationActionType.ClearDonations);
export const getDonations = createAction(
  DonationActionType.GetDonations,
  props<{ findParams: FindDonationsParams; keep?: boolean }>()
);
export const getLeads = createAction(
  DonationActionType.GetLeads,
  props<{ findParams: FindLeadsParams }>()
);
export const getDonationsSuccess = createAction(
  DonationActionType.GetDonationsSuccess,
  props<{
    resultsCount: number;
    results: Array<Donation | Lead>;
  }>()
);

export const getDonation = createAction(
  DonationActionType.GetDonation,
  props<{ id: string }>()
);
export const getDonationSuccess = createAction(
  DonationActionType.GetDonationSuccess,
  props<{ donation: Donation | Lead }>()
);
export const getLead = createAction(
  DonationActionType.GetLead,
  props<{ id: string }>()
);

export const getDonationByCode = createAction(
  DonationActionType.GetDonationByCode,
  props<{ donationCode: string }>()
);
export const getDonationByCodeSuccess = createAction(
  DonationActionType.GetDonationByCodeSuccess,
  props<{ donation: Donation }>()
);

export const createDonation = createAction(
  DonationActionType.CreateDonation,
  props<{ donation: Donation }>()
);
export const createDonationSuccess = createAction(
  DonationActionType.CreateDonationSuccess,
  props<{ donation: Donation }>()
);
export const createDonationError = createAction(
  DonationActionType.CreateDonationError,
);

export const createLead = createAction(
  DonationActionType.CreateLead,
  props<{ lead: Lead }>()
);
export const createLeadSuccess = createAction(
  DonationActionType.CreateLeadSuccess,
  props<{ lead: Lead }>()
);
export const createLeadError = createAction(
  DonationActionType.CreateLeadError,
);

export const updateDonation = createAction(
  DonationActionType.UpdateDonation,
  props<{ id: string; donation: any }>()
);
export const updateDonationSuccess = createAction(
  DonationActionType.UpdateDonationSuccess,
  props<{ donation: Donation }>()
);
export const updateDonationError = createAction(
  DonationActionType.UpdateDonationError,
);

export const updateLead = createAction(
  DonationActionType.UpdateLead,
  props<{ id: string; lead: any }>()
);
export const updateLeadSuccess = createAction(
  DonationActionType.UpdateLeadSuccess,
  props<{ lead: Lead }>()
);
export const updateLeadError = createAction(
  DonationActionType.UpdateLeadError,
);

export const convertLeadToDonation = createAction(
  DonationActionType.ConvertLeadToDonation,
  props<{ id: string; lead: Lead }>()
);
export const convertLeadToDonationSuccess = createAction(
  DonationActionType.ConvertLeadToDonationSuccess,
  props<{ donation: Donation }>()
);

export const updateDonationState = createAction(
  DonationActionType.UpdateDonationState,
  props<{ donationId: string; state: DonationStateDTO }>()
);
export const updateDonationStateSuccess = createAction(
  DonationActionType.UpdateDonationStateSuccess,
  props<{ donation: Donation }>()
);

export const getPricing = createAction(DonationActionType.GetPricing, props<{ zip?: string; charityId?: string }>());
export const getPricingSuccess = createAction(
  DonationActionType.GetPricingSuccess,
  props<{ pricing: Pricing }>()
);

export const getHistory = createAction(
  DonationActionType.GetHistory,
  props<{ itemIds: string[] }>()
);
export const getHistorySuccess = createAction(
  DonationActionType.GetHistorySuccess,
  props<{ history: Array<HistoryEvent> }>()
);

export const getDeclinedHistory = createAction(
  DonationActionType.GetDeclinedHistory,
  props<{ donationId: string }>()
);
export const getDeclinedHistorySuccess = createAction(
  DonationActionType.GetDeclinedHistorySuccess,
  props<{ history: Array<HistoryEvent> }>()
);

export const getPaymentReceipt = createAction(
  DonationActionType.GetPaymentReceipt,
  props<{ donationCode: string }>()
);
export const getPaymentReceiptSuccess = createAction(
  DonationActionType.GetPaymentReceiptSuccess,
  props<{ url: string }>()
);
export const setPendingDonationsCount = createAction(
  DonationActionType.SetPendingDonationsCount,
  props<{ count: number }>()
);

export const sendPartnerReminder = createAction(
  DonationActionType.SendPartnerReminder,
  props<{ donation: Donation; trigger: NotificationTrigger }>()
);
export const sendPartnerReminderSuccess = createAction(
  DonationActionType.SendPartnerReminderSuccess,
  props<{ donation: Donation; trigger: NotificationTrigger }>()
);

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AppType } from '@domains';
import { Environment, ENVIRONMENT } from '@domains';

@Component({
  selector: 'rspl-content-zoom',
  templateUrl: './content-zoom.component.html',
  styles: [
    'button {background-color: var(--white); color: var(--black)}',
    'button.app-3PL {background-color: var(--main);}',
    'button.app-3PL mat-icon {color: var(--text-on-primary);}',
    'button.app-Captain {background-color: var(--main);}',
    'button.app-Captain mat-icon {color: var(--text-on-primary);}'
  ]
})
export class ContentZoomComponent implements OnInit {
  @Output() zoomUpdated = new EventEmitter();
  app: AppType;


  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
  ) {
    this.app = this.config.app;
  }

  ngOnInit(): void {
  }

  public zoomPage(): void {
    this.zoomUpdated.emit();
  }

}

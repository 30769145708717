import { createAction, props } from '@ngrx/store';
import { StripePayments } from '@domains';

export enum PaymentsActionType {
  GetPayments = '[Payments] Get Payments',
  GetPaymentsSuccess = '[Payments] Get Payments Success'
}

export const getPayments = createAction(PaymentsActionType.GetPayments, props<{ id: string, limit?: number, last?: string }>());
export const getPaymentsSuccess = createAction(PaymentsActionType.GetPaymentsSuccess, props<{
  results: StripePayments
}>());



import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent, Destructible } from '@rspl-ui';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

export interface ExportDialogData {
  exporter: MatTableExporterDirective;
  fileName: string;
}

@Component({
  selector: 'rspl-grid-export',
  templateUrl: './grid-export.component.html',
  styleUrls: ['./grid-export.component.scss'],
})
export class GridExportComponent
  extends Destructible
  implements OnInit, OnDestroy
{
  isExporting = false;
  isExported = false;

  constructor(
    public reference: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.data.exporter.exportStarted
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isExporting = true;
        this.isExported = false;
      });
    this.data.exporter.exportCompleted
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isExporting = false;
        this.isExported = true;
        this.reference.close();
      });
  }

  exportSelection() {
    this.data.exporter.hiddenColumns = [7];
    this.data.exporter.exportTable('csv', {
      fileName:
        this.data.fileName + ' ' + moment(new Date()).format('YYYY-MM-DD'),
    });
  }

  exportAll() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageAction } from '@domains';
import { Responsive, ResponsiveService } from '../responsive';

@Component({
  selector: 'rspl-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent extends Responsive {
  @Input() cardTitle?: string;
  @Input() cardTitleLink?: string[];
  @Output() onTitleClick = new EventEmitter<void>();
  #actions: PageAction[] = [];
  @Input() set actions(actions: PageAction[]) {
    this.#actions = actions;
    this.visibleActionsCnt = this.actions?.filter(x => !x.hidden || !x.hidden())?.length;
  }
  get actions(): PageAction[] {
    return this.#actions;
  }
  visibleActionsCnt = 0;

  constructor(public override responsiveService: ResponsiveService) {
    super(responsiveService);
  }
}

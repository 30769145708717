import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Screening } from '@domains';
import { BaseApiService } from '../base-api.service';
import { Environment, ENVIRONMENT } from '@domains';

@Injectable({
  providedIn: 'root',
})
export class ScreeningService extends BaseApiService<Screening> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(config, http, 'screenings', Screening, 'Screening');
  }
}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxPrintModule } from 'ngx-print';
import { AvailabilityComponent } from './availability/availability.component';
import { CancelDonationDialogComponent } from './cancel-donation-dialog';
import { CardComponent } from './card/card.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContentZoomComponent } from './content-zoom';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DonationEstimateComponent } from './donation-estimate/donation-estimate.component';
import { DonationSpecificationComponent } from './donation-specification/donation-specification.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FullScreenLoaderComponent } from './full-screen-loader';
import { HealthCheckComponent } from './health-check';
import { HistoryComponent } from './history';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InformationDialogComponent } from './information-dialog';
import { InputAudioComponent } from './input-audio/input-audio.component';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { LoaderComponent, LoaderInterceptorService } from './loader';
import { ItemLookupComponent } from './lookup';
import { CharityLookupComponent } from './lookup/charity-lookup/charity-lookup.component';
import { MarketLookupComponent } from './lookup/market-lookup/market-lookup.component';
import { PartnerLookupComponent } from './lookup/partner-lookup/partner-lookup.component';
import { StoreLookupComponent } from './lookup/store-lookup/store-lookup.component';
import { TruckLookupComponent } from './lookup/truck-lookup/truck-lookup.component';
import { PhotoUploadComponent, SelectPhotoComponent } from './photo-upload';
import { RequestRescheduleDialogComponent } from './request-reschedule-dialog/request-reschedule-dialog.component';
import { SafePipe } from './safe/safe.pipe';
import { SignatureComponent } from './signature/signature.component';
import { TaxReceiptComponent } from './tax-receipt/tax-receipt.component';
import { ThemeButtonComponent } from './theme/theme-button/theme-button.component';
import { UsStatesSelectorComponent } from './us-states-selector/us-states-selector.component';
import { UtilityService } from './util';
import { DisableDoubleClickDirective } from './util/disable-double-click.directive';
import { DoubleClickDirective } from './util/double-click.directive';
import { WeekPickerComponent } from './week-picker';

@NgModule({
  declarations: [
    DoubleClickDirective,
    ConfirmDialogComponent,
    InformationDialogComponent,
    DisableDoubleClickDirective,
    LoaderComponent,
    FileUploadComponent,
    DatePickerComponent,
    InputAudioComponent,
    CardComponent,
    UsStatesSelectorComponent,
    ContentZoomComponent,
    HealthCheckComponent,
    HistoryComponent,
    WeekPickerComponent,
    JsonViewerComponent,
    CharityLookupComponent,
    ItemLookupComponent,
    MarketLookupComponent,
    PartnerLookupComponent,
    StoreLookupComponent,
    TruckLookupComponent,
    InfoBoxComponent,
    DonationEstimateComponent,
    DonationSpecificationComponent,
    SelectPhotoComponent,
    ThemeButtonComponent,
    AvailabilityComponent,
    TaxReceiptComponent,
    PhotoUploadComponent,
    SafePipe,
    SignatureComponent,
    FullScreenLoaderComponent,
    RequestRescheduleDialogComponent,
    CancelDonationDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxMapboxGLModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    NgxPrintModule,
    MatRadioModule,
    MatButtonToggleModule
  ],
  exports: [
    DoubleClickDirective,
    ConfirmDialogComponent,
    InformationDialogComponent,
    DisableDoubleClickDirective,
    LoaderComponent,
    FileUploadComponent,
    DatePickerComponent,
    InputAudioComponent,
    CardComponent,
    UsStatesSelectorComponent,
    ContentZoomComponent,
    HealthCheckComponent,
    HistoryComponent,
    WeekPickerComponent,
    JsonViewerComponent,
    CharityLookupComponent,
    ItemLookupComponent,
    MarketLookupComponent,
    PartnerLookupComponent,
    StoreLookupComponent,
    TruckLookupComponent,
    InfoBoxComponent,
    DonationEstimateComponent,
    DonationSpecificationComponent,
    SelectPhotoComponent,
    ThemeButtonComponent,
    AvailabilityComponent,
    TaxReceiptComponent,
    PhotoUploadComponent,
    SafePipe,
    SignatureComponent,
    FullScreenLoaderComponent,
    RequestRescheduleDialogComponent,
    CancelDonationDialogComponent
  ],
  providers: [],
})
export class RsplUIModule {
  public static forRoot(): ModuleWithProviders<RsplUIModule> {
    return {
      ngModule: RsplUIModule,
      providers: [
        RsplUIModule,
        MapService,
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true },
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptorService,
          multi: true,
        },
        UtilityService,
        LoaderInterceptorService,
        NgxImageCompressService,
        SafePipe,
      ],
    };
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Partner } from '@domains';
import { PartnerService } from '@rspl-api';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-partner-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
})
export class PartnerLookupComponent extends LookupComponent<Partner> {
  constructor(
    protected override apiService: PartnerService,
    protected override dialog: MatDialog
  ) {
    super(apiService, dialog);
  }
}

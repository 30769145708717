import { createAction, props } from '@ngrx/store';
import { FindZoneParams, Zone } from '@domains';

export enum ZoneActionType {
  CreateZone = '[Zone] Create Zone',
  CreateZoneSuccess = '[Zone] Create Zone Success',

  UpdateZone = '[Zone] Update Zone',
  UpdateZoneSuccess = '[Zone] Update Zone Success',

  GetZones = '[Zone] Get Zones',
  GetZonesSuccess = '[Zone] Get Zones Success',

  GetZone = '[Zone] Get Zone',
  GetZoneSuccess = '[Zone] Get Zone Success',

  DeleteZone = '[Zone] Delete Zone',
  DeleteZoneSuccess = '[Zone] Delete Zone Success'
}

export const createZone = createAction(ZoneActionType.CreateZone, props<{ zone: Zone }>());
export const createZoneSuccess = createAction(ZoneActionType.CreateZoneSuccess, props<{ zone: Zone }>());

export const updateZone = createAction(ZoneActionType.UpdateZone, props<{ zone: Zone }>());
export const updateZoneSuccess = createAction(ZoneActionType.UpdateZoneSuccess, props<{ zone: Zone }>());

export const getZones = createAction(ZoneActionType.GetZones, props<{ findParams: FindZoneParams }>());
export const getZonesSuccess = createAction(ZoneActionType.GetZonesSuccess, props<{ zones: Array<Zone> }>());

export const getZone = createAction(ZoneActionType.GetZone, props<{ zoneId: string }>());
export const getZoneSuccess = createAction(ZoneActionType.GetZoneSuccess, props<{ zone: Zone }>());

export const deleteZone = createAction(ZoneActionType.DeleteZone, props<{ zoneId: string }>());
export const deleteZoneSuccess = createAction(ZoneActionType.DeleteZoneSuccess, props<{ zoneId: string }>());


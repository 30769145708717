import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Charity } from '@domains';
import { CharityService } from '@rspl-api';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-charity-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
})
export class CharityLookupComponent extends LookupComponent<Charity> {
  @Input() disableNotReady = false;
  override disableOption = (item: Charity) =>
    this.disableNotReady && item.meta?.isReady === false;
  override disabledLabel = ' (Not Ready)';

  constructor(
    protected override apiService: CharityService,
    protected override dialog: MatDialog
  ) {
    super(apiService, dialog);
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CharityStore } from '@domains';
import { StoresService } from '@rspl-api';
import { LookupComponent } from '../lookup.component';

@Component({
  selector: 'rspl-store-lookup',
  templateUrl: '../lookup.component.html',
  styleUrls: ['../lookup.component.scss'],
})
export class StoreLookupComponent extends LookupComponent<CharityStore> {
  constructor(
    protected override apiService: StoresService,
    protected override dialog: MatDialog
  ) {
    super(apiService, dialog);
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Item } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ItemService extends BaseApiService<Item> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(config, http, 'items', Item, 'Item');
  }

  override serialize(item: Item) {
    return {
      ...Serialize({ ...item }, Item),
    };
  }

  override deserialize(data: any): Item {
    const res = new Item({
      ...Deserialize(data, Item),
    });
    res.setInitialValue();
    return res;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppType,
  AuthenticationModel,
  BuildType,
  Charity,
  ENVIRONMENT,
  Environment,
  LoginModel,
  Partner,
  Role,
  User,
  UserPermissions,
} from '@domains';
import { CharityService, LocalStorageService, PartnerService } from '@rspl-api';
import { Deserialize, Serialize } from 'cerialize';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

export const AFTER_LOGIN = 'rspl-after-login-path';

declare let pendo: any;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _authData?: AuthenticationModel;
  private authData: BehaviorSubject<AuthenticationModel | undefined>;
  public authData$: Observable<AuthenticationModel | undefined>;
  partner?: Partner;
  charity?: Charity;
  private loggedIn = false;
  private isLoggedIn: BehaviorSubject<boolean>;
  public isLoggedIn$: Observable<boolean>;

  private onLogout = new Subject<void>();
  public onLogout$ = this.onLogout.asObservable();
  private isPendoInitialized = false;

  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
    private partnerService: PartnerService,
    private charityService: CharityService,
    private router: Router,
    private localStorage: LocalStorageService,
    private zone: NgZone
  ) {
    const authData = JSON.parse(
      this.localStorage.getItem('rspl-user') || 'null'
    );
    if (authData) {
      this._authData = new AuthenticationModel(authData);
    } else {
      this._authData = undefined;
    }
    this.loggedIn = !!this._authData;
    this.authData = new BehaviorSubject<AuthenticationModel | undefined>(
      Object.keys(this._authData || {}).length > 0 ? this._authData : undefined
    );
    this.authData$ = this.authData.asObservable();
    this.isLoggedIn = new BehaviorSubject<boolean>(this.loggedIn);
    this.isLoggedIn$ = this.isLoggedIn.asObservable();
    if (this.config.buildType === BuildType.PRODUCTION) {
      this.initializePendo();
    }
  }

  getPartner(expand?: string[]): Observable<Partner | undefined> {
    if (!this._authData?.partnerId) return of(undefined);
    if (this.partner) return of(this.partner);
    return this.partnerService
      .find(this._authData.partnerId, !!expand, expand)
      .pipe(
        map((partner) => (this.partner = partner)),
        take(1)
      );
  }

  getCharity(): Observable<Charity | undefined> {
    if (!this._authData?.charityId) return of(undefined);
    if (this.charity) return of(this.charity);
    return this.charityService.find(this._authData.charityId).pipe(
      map((charity) => (this.charity = charity)),
      take(1)
    );
  }

  signUp(user: User): Observable<AuthenticationModel> {
    return this.http
      .post<AuthenticationModel>(
        this.config.urls.baseUrl + `/users/sign_up`,
        Serialize(user, User)
      )
      .pipe(
        switchMap((authData: AuthenticationModel) => {
          return this.setLoggedIn(authData);
        })
      );
  }

  login(user: LoginModel): Observable<AuthenticationModel> {
    return this.http
      .post<AuthenticationModel>(
        this.config.urls.baseUrl + `/users/sign_in`,
        Serialize(user, LoginModel)
      )
      .pipe(
        switchMap((authData: any) => {
          return this.setLoggedIn(authData);
        })
      );
  }

  refreshToken(): Observable<AuthenticationModel> {
    return this.http
      .post<AuthenticationModel>(this.config.urls.baseUrl + `/users/refresh`, {
        token: JSON.parse(this.localStorage.getItem('rspl-user') || '{}')
          ?.authorization?.refresh,
      })
      .pipe(
        switchMap((authData: AuthenticationModel) => {
          return this.setLoggedIn(authData, true);
        })
      );
  }

  setLoggedIn(
    authData: AuthenticationModel,
    isRefresh = false
  ): Observable<AuthenticationModel> {
    const res = new AuthenticationModel(
      Deserialize(
        {
          ...authData,
          permissions: Deserialize(authData.permissions, UserPermissions),
        },
        AuthenticationModel
      )
    );
    const roles: Array<Role> = new Array<Role>();
    switch (this.config.app) {
      case AppType.CAPTAIN:
      case AppType.ZENDESK:
        roles.push(Role.CAPTAIN);
        break;
      case AppType.CHARITY:
        roles.push(Role.CHARITY);
        roles.push(Role.STORE);
        break;
      case AppType.TPL:
        roles.push(Role.PARTNER);
        break;
      case AppType.DRIVER:
        roles.push(Role.DRIVER);
        break;
    }
    let canAccess = false;
    let i = 0;
    while (!canAccess && i < roles.length) {
      canAccess = (authData.authorization?.roles || []).includes(roles[i]);
      i++;
    }
    if (!canAccess) {
      throw new Error();
    }

    this.localStorage.setItem('rspl-user', JSON.stringify(res));
    this._authData = res;
    this.authData.next(this._authData);
    this.loggedIn = true;
    this.isLoggedIn.next(this.loggedIn);
    if (!isRefresh) {
      if (res.partnerId) {
        return this.partnerService.find(res.partnerId).pipe(
          take(1),
          map((partner) => {
            this.partner = partner;
            return res;
          })
        );
      } else if (res.charityId) {
        return this.charityService.find(res.charityId).pipe(
          take(1),
          map((charity) => {
            this.charity = charity;
            return res;
          })
        );
      }
    }
    return of(res);
  }

  logout(logoutApi = true): void {
    if (logoutApi) {
      this.logoutApi()
        .pipe(
          catchError((err) => {
            this.logoutLocal();
            return err;
          })
        )
        .subscribe(() => {
          this.logoutLocal();
        });
    } else {
      this.logoutLocal();
    }
  }

  private logoutLocal() {
    const afterLogin = this.localStorage.getItem(AFTER_LOGIN);
    this.localStorage.clear();
    if (afterLogin) {
      this.localStorage.setItem(AFTER_LOGIN, afterLogin);
    }
    this.loggedIn = false;
    this.partner = undefined;
    this.charity = undefined;
    this.authData.next(undefined);
    this.isLoggedIn.next(this.loggedIn);
    this.onLogout.next();
    setTimeout(() => {
      this.zone.run(() => {
        this.router.navigate(['/', 'login']);
      });
    }, 100);
  }

  private logoutApi() {
    if (this.localStorage.getItem('rspl-user')) {
      return this.http
        .post(this.config.urls.baseUrl + `/users/sign_out`, {})
        .pipe(
          take(1),
          catchError(() => of({}))
        );
    } else {
      return of({});
    }
  }

  private initializePendo() {
    this.isLoggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        const pendoMetadata = {
          visitor: {
            id: this._authData?.id,
            email: this._authData?.email,
            full_name: this._authData?.name,
            application_type: this.config.app,
          },
          account: {
            id: 'ACCOUNT-UNIQUE-ID',
          },
        };
        if (!this.isPendoInitialized) {
          pendo.initialize(pendoMetadata);
          this.isPendoInitialized = true;
        } else {
          // If Pendo is already initialized, update metadata in case the logged-in user changed
          pendo.identify(pendoMetadata);
        }
      }
    });
  }
}

<ng-template #Data let-change="change">
  <rspl-card [cardTitle]="change.user">
    <div fxLayout="column" class="history-data-wrapper">
      <table class="static-table full-width">
        <thead>
        <tr>
          <th></th>
          <th *ngIf="!change.oneValue">Old Value</th>
          <th *ngIf="!change.oneValue">New Value</th>
          <th *ngIf="change.oneValue">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let field of change.fields">
          <td>{{field.name}}</td>
          <td *ngIf="!change.oneValue">
            <p [innerHTML]="field.oldValue"></p>
          </td>
          <td>
            <p [innerHTML]="field.newValue"></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </rspl-card>
</ng-template>
<div class="history-wrapper">
  <mat-accordion *ngIf="smallWindows.includes(windowSize)" [multi]="true">
    <mat-expansion-panel *ngFor="let change of changes">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{change.event}}
        </mat-panel-title>
        <mat-panel-description>
          {{change.time}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="Data" [ngTemplateOutletContext]="{change: change}"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>


  <div class="history" *ngIf="!smallWindows.includes(windowSize)">
    <div class="separator"></div>
    <div *ngFor="let change of changes" class="timeline-entry">
      <p class="date">{{change.time}}</p>
      <p class="event">{{change.event}}</p>
      <ng-container [ngTemplateOutlet]="Data" [ngTemplateOutletContext]="{change: change}"></ng-container>
    </div>
  </div>
</div>

// tslint:disable: max-line-length
import { Charity, CharityStore, Screening } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './charity.actions';

export interface CharityState {
  charities: Array<Charity>;
  charitiesTotalCount: number;
  charity: Charity;
  deletedCharityId: number;
  createdStore: CharityStore;
  stores: Array<CharityStore>;
  store: CharityStore;
  deletedStoreId: number;
  screening: Screening | null;
}

const initialState: CharityState = {
  charities: new Array<Charity>(),
  charitiesTotalCount: 0,
  charity: new Charity(),
  deletedCharityId: 0,
  createdStore: new CharityStore(),
  stores: new Array<CharityStore>(),
  store: new CharityStore(),
  deletedStoreId: 0,
  screening: null,
};

const reducer = createReducer(
  initialState,

  on(fromActions.getCharities, (state, { findParams, keep }) => {
    return {
      ...state,
      charitiesTotalCount: keep ? state.charitiesTotalCount : 0,
      charities: keep ? state.charities : [],
    };
  }),
  on(fromActions.getCharitiesSuccess, (state, { resultsCount, results }) => ({
    ...state,
    charitiesTotalCount: resultsCount,
    charities: results,
  })),
  on(fromActions.getCharity, (state, { keep }) => ({
    ...state,
    charity: keep ? state.charity : null,
  })),
  on(fromActions.getCharitySuccess, (state, action: any) => ({
    ...state,
    charity: action.charity,
  })),

  on(fromActions.createCharitySuccess, (state, action: any) => ({
    ...state,
    charity: action.charity,
  })),

  on(fromActions.updateCharitySuccess, (state, action: any) => ({
    ...state,
    charity: action.charity,
  })),

  on(fromActions.deleteCharitySuccess, (state, action: any) => ({
    ...state,
    deletedCharityId: action.charityId,
  })),

  on(fromActions.getCharityScreeningSuccess, (state, action: any) => ({
    ...state,
    screening: action.screening,
  })),

  on(fromActions.getCharityScreening, (state, action: any) => ({
    ...state,
    screening: null,
  }))
);

export function charityReducer(
  state: CharityState | undefined,
  action: Action
) {
  return reducer(state, action);
}

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { RsplUIModule } from '@rspl-ui';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapComponent } from './map';
import { ENVIRONMENT, Environment } from '@domains';
import { SimpleAddressInputComponent } from './simple-address-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdvancedAddressInputComponent } from './advanced-address-input';
import { LaddaModule } from 'angular2-ladda';
import { AddressInputFormComponent } from './address-input-form/address-input-form.component';

@NgModule({
  declarations: [
    SimpleAddressInputComponent,
    MapComponent,
    AdvancedAddressInputComponent,
    AddressInputFormComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    NgxMapboxGLModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    RsplUIModule,
    MatTooltipModule,
    MatAutocompleteModule,
    LaddaModule,
  ],
  exports: [
    SimpleAddressInputComponent,
    MapComponent,
    AdvancedAddressInputComponent,
    AddressInputFormComponent,
  ],
  providers: [],
})
export class RsplMapModule {
  public static forRoot(): ModuleWithProviders<RsplMapModule> {
    return {
      ngModule: RsplMapModule,
      providers: [RsplMapModule, MapService],
    };
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CharityState } from './charity.reducers';

// tslint:disable: max-line-length
export const charityFeatureStateSelector =
  createFeatureSelector<CharityState>('charity');

export const selectCharities = createSelector(
  charityFeatureStateSelector,
  (state: CharityState) => state.charities
);
export const selectCharitiesTotalCount = createSelector(
  charityFeatureStateSelector,
  (state: CharityState) => state.charitiesTotalCount
);

export const selectCharity = createSelector(
  charityFeatureStateSelector,
  (state: CharityState) => state.charity
);
export const selectCharityScreening = createSelector(
  charityFeatureStateSelector,
  (state: CharityState) => state.screening
);

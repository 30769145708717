import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Charity, CharityStore, Screening } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { BaseApiService } from '../base-api.service';
import { Environment, ENVIRONMENT } from '@domains';
import { MarketsService } from './markets.service';

@Injectable({
  providedIn: 'root',
})
export class CharityService extends BaseApiService<Charity> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
    private marketsService: MarketsService
  ) {
    super(config, http, 'charities', Charity, 'Charity', [], ['market']);
  }

  override deserialize(data: any): Charity {
    const res = new Charity({
      ...Deserialize(data, Charity),
      ...(data.stores
        ? {
            stores: data.stores.map(
              (s: any) => new CharityStore(Deserialize(s, CharityStore))
            ),
          }
        : {}),
      ...(data.screening
        ? {
            screening: new Screening(Deserialize(data.screening, Screening)),
          }
        : {}),
    });
    res.setInitialValue();
    return res;
  }

  override serialize(item: Charity) {
    return {
      ...Serialize(
        {
          ...item,
          ...(item.market
            ? {
                market: { ...this.marketsService.serialize(item.market) },
              }
            : {}),
        },
        Charity
      ),
    };
  }
}

import { createAction, props } from '@ngrx/store';
import { FindParams, HistoryEvent, Notification } from '@domains';

export enum NotificationsActionType {
  CreateNotification = '[Notification] Create Notification',
  CreateNotificationSuccess = '[Notification] Create Notification Success',

  UpdateNotification = '[Notification] Update Notification',
  UpdateNotificationSuccess = '[Notification] Update Notification Success',

  ClearNotificationsFindParams = '[Charity] Clear Notifications Find Params',
  GetNotifications = '[Notification] Get Notifications',
  GetNotificationsSuccess = '[Notification] Get Notifications Success',

  GetNotification = '[Notification] Get Notification',
  GetNotificationSuccess = '[Notification] Get Notification Success',

  DeleteNotification = '[Notification] Delete Notification',
  DeleteNotificationSuccess = '[Notification] Delete Notification Success',

  RestoreNotification = '[Notification] Restore Notification',
  RestoreNotificationSuccess = '[Notification] Restore Notification Success',

  GetNotificationHistory = '[Notification] Get Notification History',
  GetNotificationHistorySuccess = '[Notification] Get Notification History Success',
}

export const createNotification = createAction(NotificationsActionType.CreateNotification, props<{ notification: Notification }>());
export const createNotificationSuccess = createAction(NotificationsActionType.CreateNotificationSuccess, props<{ notification: Notification }>());

export const updateNotification = createAction(NotificationsActionType.UpdateNotification, props<{ notification: Notification }>());
export const updateNotificationSuccess = createAction(NotificationsActionType.UpdateNotificationSuccess, props<{ notification: Notification }>());

export const clearNotificationsFindParams = createAction(NotificationsActionType.ClearNotificationsFindParams);
export const getNotifications = createAction(NotificationsActionType.GetNotifications, props<{ findParams: FindParams }>());
export const getNotificationsSuccess = createAction(NotificationsActionType.GetNotificationsSuccess, props<{ notifications: Array<Notification>, resultsCount: number }>());

export const getNotification = createAction(NotificationsActionType.GetNotification, props<{ id: string, keep?: boolean }>());
export const getNotificationSuccess = createAction(NotificationsActionType.GetNotificationSuccess, props<{ notification: Notification }>());

export const deleteNotification = createAction(NotificationsActionType.DeleteNotification, props<{ notificationId: string }>());
export const deleteNotificationSuccess = createAction(NotificationsActionType.DeleteNotificationSuccess, props<{ notificationId: string }>());

export const restoreNotification = createAction(NotificationsActionType.RestoreNotification, props<{ notificationId: string }>());
export const restoreNotificationSuccess = createAction(NotificationsActionType.RestoreNotificationSuccess, props<{ notificationId: string }>());

export const getNotificationHistory = createAction(NotificationsActionType.GetNotificationHistory, props<{ itemIds: string[] }>());
export const getNotificationHistorySuccess = createAction(NotificationsActionType.GetNotificationHistorySuccess, props<{ history: Array<HistoryEvent> }>());

<rspl-card class="schedule-wrapper">
  <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)" #tabGroup *ngIf="!config.onlyDefault">
    <mat-tab label="Default Schedule">
    </mat-tab>
    <mat-tab label="Weekly Schedule" [disabled]="isDefault && !schedule?.schedule?.id">
    </mat-tab>
  </mat-tab-group>
  <rspl-week-picker #weekPicker *ngIf="!isDefault && !day"
                   (weekChanged)="schedule.fetchData(weekPicker.week, weekPicker.year)"></rspl-week-picker>
  <app-schedule-form #schedule [config]="config" [isDefault]="isDefault" [week]="weekPicker?.week" [day]="day"
                     [year]="weekPicker?.year" [showWarning]="!config.onlyDefault" [showMap]="showMap">
  </app-schedule-form>
</rspl-card>

import { autoserialize } from 'cerialize';

export class Address implements IAddress {
  @autoserialize street?: string;
  @autoserialize city?: string;
  @autoserialize state?: string;
  @autoserialize zip?: string;
  @autoserialize secondary?: string;
  @autoserialize lat?: number;
  @autoserialize lng?: number;
  lngLat?: [number, number];

  constructor(payload?: IAddress) {
    this.street = payload?.street;
    this.city = payload?.city;
    this.state = payload?.state?.replace('US-', '');
    this.zip = payload?.zip;
    this.secondary = payload?.secondary;
    this.lat = payload?.lat;
    this.lng = payload?.lng;
    if (this.lng && this.lat) {
      this.lngLat = [this.lng, this.lat];
    }
  }

  public get mapBoxAddress(): string {
    return '' + this.street + this.city + ' ' + this.state + ' ' + this.zip;
  }

  public get fullAddress(): string {
    const addr = (
      (this.street ? this.street + ', ' : '') +
      (this.city ? this.city + ', ' : '') +
      (this.state ? this.state + ', ' : '') +
      (this.zip ? this.zip : '') +
      ((this.secondary?.length || 0) > 0
        ? ' #' + this.secondary
        : '')
    );
    return addr.length > 0 ? addr : '/';
  }
}

export interface IAddress {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  secondary?: string;
  lat?: number;
  lng?: number;
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnerState } from './partner.reducers';

// tslint:disable: max-line-length
export const partnerFeatureStateSelector = createFeatureSelector<PartnerState>('partner');

export const selectPartners = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.partners);
export const selectPartnersTotalCount = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.partnersTotalCount);
export const selectPartnersFindParams = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.findParams);

export const selectPartner = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.partner);

export const selectPartnersForFeatureFlags = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.partnersForFeatureFlags);
export const selectPartnersForFeatureFlagsTotalCount = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.partnersForFeatureFlagsTotalCount);
export const selectPartnersForFeatureFlagsFindParams = createSelector(partnerFeatureStateSelector, (state: PartnerState) => state.findParamsForFeatureFlags);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ZoneService } from '@rspl-api';
import * as fromActions from './zone.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable()
export class ZoneEffects {

  createZone = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createZone),
      mergeMap((payload) =>
        this.service.create(payload.zone)
          .pipe(
            map((result) => {
                return fromActions.createZoneSuccess({
                  zone: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateZone = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateZone),
      mergeMap((payload) =>
        this.service.update(payload.zone.id, payload.zone)
          .pipe(
            map((result) => {
                return fromActions.updateZoneSuccess({
                  zone: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getZones = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getZones),
      switchMap((params) =>
        this.service.filter(params.findParams)
          .pipe(
            map((results) => {
                return fromActions.getZonesSuccess({
                  zones: results.results
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getZone = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getZone),
      switchMap((params) =>
        this.service.find(params.zoneId)
          .pipe(
            map((result) => {
                return fromActions.getZoneSuccess({
                  zone: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  deleteZone = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteZone),
      switchMap((params) =>
        this.service.delete(params.zoneId)
          .pipe(
            map((result) => {
                return fromActions.deleteZoneSuccess({
                  zoneId: params.zoneId
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: ZoneService
  ) {
  }
}

import { Injectable } from '@angular/core';


@Injectable()
export class PageEffects {

  constructor() {
  }

}

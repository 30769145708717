<div
  class="file-container"
  [ngClass]="{error: error, disabled: disabled}"
  (click)="selectImage(file);"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <button
    *ngIf="!isUploading"
    class="primary-btn block-btn"
    type="button"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span> Upload File </span>
    </div>
  </button>
  <input #file type="file" [accept]="accept" (change)="upload($event)"/>
  <mat-spinner *ngIf="isUploading"></mat-spinner>
</div>

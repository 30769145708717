import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScheduleHistoryState } from './schedule-history.reducers';

export const scheduleHistoryFeatureStateSelector =
  createFeatureSelector<ScheduleHistoryState>('scheduleHistory');

export const selectScheduleHistory = createSelector(
  scheduleHistoryFeatureStateSelector,
  (state: ScheduleHistoryState) => state.history
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ItemState } from './item.reducers';

// tslint:disable: max-line-length
export const itemFeatureStateSelector = createFeatureSelector<ItemState>('item');

export const selectItems = createSelector(itemFeatureStateSelector, (state: ItemState) => state.items);
export const selectItemsTotalCount = createSelector(itemFeatureStateSelector, (state: ItemState) => state.itemsTotalCount);
export const selectItemsFindParams = createSelector(itemFeatureStateSelector, (state: ItemState) => state.findParams);

export const selectItem = createSelector(itemFeatureStateSelector, (state: ItemState) => state.item);


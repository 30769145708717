import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrpsState } from './trp.reducers';

// tslint:disable: max-line-length
export const trpsFeatureStateSelector = createFeatureSelector<TrpsState>('trp');

export const selectTrpsFindParams = createSelector(trpsFeatureStateSelector, (state: TrpsState) => {
  return state.findParams;
});


export const hours: { value: string, viewValue: string }[] = [
  {value: '1:00 AM', viewValue: '01:00 AM'},
  {value: '2:00 AM', viewValue: '02:00 AM'},
  {value: '3:00 AM', viewValue: '03:00 AM'},
  {value: '4:00 AM', viewValue: '04:00 AM'},
  {value: '5:00 AM', viewValue: '05:00 AM'},
  {value: '6:00 AM', viewValue: '06:00 AM'},
  {value: '7:00 AM', viewValue: '07:00 AM'},
  {value: '8:00 AM', viewValue: '08:00 AM'},
  {value: '9:00 AM', viewValue: '09:00 AM'},
  {value: '10:00 AM', viewValue: '10:00 AM'},
  {value: '11:00 AM', viewValue: '11:00 AM'},
  {value: '12:00 AM', viewValue: '12:00 AM'},
  {value: '1:00 PM', viewValue: '01:00 PM'},
  {value: '2:00 PM', viewValue: '02:00 PM'},
  {value: '3:00 PM', viewValue: '03:00 PM'},
  {value: '4:00 PM', viewValue: '04:00 PM'},
  {value: '5:00 PM', viewValue: '05:00 PM'},
  {value: '6:00 PM', viewValue: '06:00 PM'},
  {value: '7:00 PM', viewValue: '07:00 PM'},
  {value: '8:00 PM', viewValue: '08:00 PM'},
  {value: '9:00 PM', viewValue: '09:00 PM'},
  {value: '10:00 PM', viewValue: '10:00 PM'},
  {value: '11:00 PM', viewValue: '11:00 PM'},
  {value: '12:00 PM', viewValue: '12:00 PM'},
];
export const halfHours: { value: string, viewValue: string }[] = [
  {value: '0:30 AM', viewValue: '00:30 AM'},
  {value: '1:00 AM', viewValue: '01:00 AM'},
  {value: '1:30 AM', viewValue: '01:30 AM'},
  {value: '2:00 AM', viewValue: '02:00 AM'},
  {value: '2:30 AM', viewValue: '02:30 AM'},
  {value: '3:00 AM', viewValue: '03:00 AM'},
  {value: '3:30 AM', viewValue: '03:30 AM'},
  {value: '4:00 AM', viewValue: '04:00 AM'},
  {value: '4:30 AM', viewValue: '04:30 AM'},
  {value: '5:00 AM', viewValue: '05:00 AM'},
  {value: '5:30 AM', viewValue: '05:30 AM'},
  {value: '6:00 AM', viewValue: '06:00 AM'},
  {value: '6:30 AM', viewValue: '06:30 AM'},
  {value: '7:00 AM', viewValue: '07:00 AM'},
  {value: '7:30 AM', viewValue: '07:30 AM'},
  {value: '8:00 AM', viewValue: '08:00 AM'},
  {value: '8:30 AM', viewValue: '08:30 AM'},
  {value: '9:00 AM', viewValue: '09:00 AM'},
  {value: '9:30 AM', viewValue: '09:30 AM'},
  {value: '10:00 AM', viewValue: '10:00 AM'},
  {value: '10:30 AM', viewValue: '10:30 AM'},
  {value: '11:00 AM', viewValue: '11:00 AM'},
  {value: '11:30 AM', viewValue: '11:30 AM'},
  {value: '12:00 AM', viewValue: '12:00 AM'},
  {value: '12:30 AM', viewValue: '12:30 AM'},
  {value: '1:00 PM', viewValue: '01:00 PM'},
  {value: '1:30 PM', viewValue: '01:30 PM'},
  {value: '2:00 PM', viewValue: '02:00 PM'},
  {value: '2:30 PM', viewValue: '02:30 PM'},
  {value: '3:00 PM', viewValue: '03:00 PM'},
  {value: '3:30 PM', viewValue: '03:30 PM'},
  {value: '4:00 PM', viewValue: '04:00 PM'},
  {value: '4:30 PM', viewValue: '04:30 PM'},
  {value: '5:00 PM', viewValue: '05:00 PM'},
  {value: '5:30 PM', viewValue: '05:30 PM'},
  {value: '6:00 PM', viewValue: '06:00 PM'},
  {value: '6:30 PM', viewValue: '06:30 PM'},
  {value: '7:00 PM', viewValue: '07:00 PM'},
  {value: '7:30 PM', viewValue: '07:30 PM'},
  {value: '8:00 PM', viewValue: '08:00 PM'},
  {value: '8:30 PM', viewValue: '08:30 PM'},
  {value: '9:00 PM', viewValue: '09:00 PM'},
  {value: '9:30 PM', viewValue: '09:30 PM'},
  {value: '10:00 PM', viewValue: '10:00 PM'},
  {value: '10:30 PM', viewValue: '10:30 PM'},
  {value: '11:00 PM', viewValue: '11:00 PM'},
  {value: '11:30 PM', viewValue: '11:30 PM'},
  {value: '12:00 PM', viewValue: '12:00 PM'}
];

export enum Day {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday'
}

export const days: Day[] = [Day.monday, Day.tuesday, Day.wednesday, Day.thursday, Day.friday, Day.saturday, Day.sunday];

